import * as Yup from 'yup';
import React, { useState, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid, IconButton, MenuItem, Typography, FormHelperText
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import CustomLocationDialog from './CustomLocation';
import CustomCategoryDialog from './CustomCategory';
import InventoryLocationAPIs from '../../../utilities/api/location/AdminLocationList';
import InventoryCategoryAPIs from '../../../utilities/api/category/AdminInventoryCategoryList';
import EquipmentDetailAPIs from '../../../utilities/api/equipment/AdminEquipmentDetail';
import dayjs from 'dayjs';
import {ImageBaseURL} from "../../../utilities/api/axios";


const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  upc: Yup.number().required('UPC is required'),
  instances: Yup.number().required('Instances are required'),
  manufacturer: Yup.string().nullable(),
  location: Yup.string(),
  purchase_cost: Yup.number(),
  image: Yup.mixed().nullable(),
  category: Yup.string().nullable(),
  description: Yup.string().required('Description is required'),
  status: Yup.string(),
  purchase_date: Yup.date().nullable().typeError('Date has wrong format. Use YYYY-MM-DD'),
  warranty_expiration: Yup.date().nullable().typeError('Date has wrong format. Use YYYY-MM-DD'),
});

const styledField = {
  maxWidth: '70%',
  padding: '0px 4px 8px 6px',
};

const EditEquipmentDialog = ({ currentRow, open, onClose, update, setUpdate, flag, setFlag }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const token = user.access;

  const category = useSelector(state => state.category.category_list);
  const location = useSelector(state => state.location.location_list);
  const categories = category.map(item => item.name);
  const locations = location.map(item => item.name);

  const [openCustomLocation, setOpenCustomLocation] = useState(false);
  const [customLocation, setCustomLocation] = useState('');
  const [openCustomCategory, setOpenCustomCategory] = useState(false);
  const [customCategory, setCustomCategory] = useState('');
  const [newImage, setNewImage] = useState(null); // State for the new image
  const fileInputRef = useRef(null);

  const handleConfirmLocation = () => {
    setOpenCustomLocation(false);
    const response = InventoryLocationAPIs.LocationListPost(customLocation, token);
    response.then((result) => {
      console.log(result);
      setFlag(prev => !prev);
    }).catch((err) => {
      console.log('error', err);
    });
  };

  const handleConfirmCategory = () => {
    setOpenCustomCategory(false);
    const response = InventoryCategoryAPIs.CategoryListPost(customCategory, token);
    response.then((result) => {
      console.log(result);
      setFlag(prev => !prev);
    }).catch((err) => {
      console.log('error', err);
    });
  };

  const initialValues = {
    id: currentRow?.id || '',
    category: currentRow?.category || null,
    location: currentRow?.location || '',
    name: currentRow?.name || '',
    upc: currentRow?.upc || '',
    manufacturer: currentRow?.manufacturer || null,
    description: currentRow?.description || '',
    image: null,
    status: currentRow?.status || '',
    instances: currentRow?.instances || '',
    purchase_cost: currentRow?.purchase_cost || '',
    purchase_date: currentRow?.purchase_date ? dayjs(currentRow.purchase_date) : null,
    warranty_expiration: currentRow?.warranty_expiration ? dayjs(currentRow.warranty_expiration) : null,
    // college: currentRow?.college || '',
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Edit Equipment</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const formData = new FormData();
          for (const key in values) {
            if (values.hasOwnProperty(key) && key !== 'purchase_date' && key !== 'warranty_expiration' && key !== 'image') {
              formData.append(key, values[key]);
            }
          }
          if (values.purchase_date) {
            formData.append('purchase_date', values.purchase_date.format('YYYY-MM-DD'));
          }
          if (values.warranty_expiration) {
            formData.append('warranty_expiration', values.warranty_expiration.format('YYYY-MM-DD'));
          }
          if (values.image) {
            formData.append('image', values.image);
          }

          const response = EquipmentDetailAPIs.EquipmentDetailPut(currentRow.id, formData, token, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          response.then((result) => {
            console.log(result);
            onClose();
            setUpdate(prev => !prev);
          }).catch((error) => {
            console.log(error);
            alert(`${error.response.data}`);
          }).finally(() => {
            setSubmitting(false);
          });
        }}
      >
        {({ handleSubmit, values, touched, errors, handleChange, setFieldValue }) => {
          const handleFileChange = (event) => {
            const file = event.currentTarget.files[0];
            if (file && file.type.startsWith('image/')) {
              setFieldValue('image', file);
              setNewImage(URL.createObjectURL(file)); // Set the new image URL
            } else {
              alert('Only image files are allowed!');
            }
          };

          return (
            <Form onSubmit={handleSubmit} encType="multipart/form-data">
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>Name*</Typography>
                    <Field
                      name="name"
                      as={TextField}
                      fullWidth
                      value={values.name}
                      onChange={handleChange}
                      error={touched.name && !!errors.name}
                      helperText={touched.name && errors.name}
                      sx={styledField}
                    />
                    <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>UPC*</Typography>
                    <Field
                      name="upc"
                      as={TextField}
                      fullWidth
                      value={values.upc}
                      onChange={handleChange}
                      error={touched.upc && !!errors.upc}
                      helperText={touched.upc && errors.upc}
                      sx={styledField}
                    />
                    <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>Instances*</Typography>
                    <Field
                      name="instances"
                      as={TextField}
                      fullWidth
                      value={values.instances}
                      onChange={handleChange}
                      error={touched.instances && !!errors.instances}
                      helperText={touched.instances && errors.instances}
                      sx={styledField}
                    />
                    <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>Manufacturer</Typography>
                    <Field
                      name="manufacturer"
                      as={TextField}
                      fullWidth
                      value={values.manufacturer}
                      onChange={handleChange}
                      error={touched.manufacturer && !!errors.manufacturer}
                      helperText={touched.manufacturer && errors.manufacturer}
                      sx={styledField}
                    />
                    <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>Location</Typography>
                    <Field
                      name="location"
                      as={TextField}
                      select
                      fullWidth
                      value={values.location}
                      onChange={handleChange}
                      error={touched.location && !!errors.location}
                      helperText={touched.location && errors.location}
                      sx={styledField}
                    >
                      {locations.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>
                    <IconButton aria-label="custom-location" onClick={() => setOpenCustomLocation(true)}>
                      <AddIcon sx={{ color: 'green' }} />
                    </IconButton>
                    <CustomLocationDialog
                      openCustomLocation={openCustomLocation}
                      setCustomLocation={setCustomLocation}
                      onClose={() => setOpenCustomLocation(false)}
                      onConfirm={() => {
                        setFieldValue('location', customLocation);
                        handleConfirmLocation();
                      }}
                    />
                    <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>Purchase Cost</Typography>
                    <Field
                      name="purchase_cost"
                      as={TextField}
                      fullWidth
                      value={values.purchase_cost}
                      onChange={handleChange}
                      error={touched.purchase_cost && !!errors.purchase_cost}
                      helperText={touched.purchase_cost && errors.purchase_cost}
                      sx={styledField}
                    />
                    <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>Equipment Image</Typography>
                    {newImage ? (
                      <img src={newImage} alt="New Upload" style={{ width: '200px', height: '200px' }} />
                    ) : currentRow.image ? (
                      <img src={`${ImageBaseURL}${currentRow.image}`} alt={currentRow.image.split('/').pop()} style={{ width: '200px', height: '200px' }} />
                    ) : null}
                    <Button
                      variant="contained"
                      onClick={() => fileInputRef.current.click()}
                      sx={styledField}
                    >
                      Upload File
                    </Button>
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept="image/*" // Restrict file selection to images only
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                    {touched.image && errors.image && (
                      <FormHelperText error>{errors.image}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>Category</Typography>
                      <IconButton aria-label="custom-category" onClick={() => setOpenCustomCategory(true)}>
                        <AddIcon sx={{ color: 'green' }} />
                      </IconButton>
                    </Box>
                    <Field
                      name="category"
                      as={TextField}
                      select
                      fullWidth
                      value={values.category}
                      onChange={handleChange}
                      error={touched.category && !!errors.category}
                      helperText={touched.category && errors.category}
                      sx={styledField}
                    >
                      {categories.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>
                    <CustomCategoryDialog
                      openCustomCategory={openCustomCategory}
                      setCustomCategory={setCustomCategory}
                      onClose={() => setOpenCustomCategory(false)}
                      onConfirm={() => {
                        setFieldValue('category', customCategory);
                        handleConfirmCategory();
                      }}
                    />
                    <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>Status</Typography>
                    <Field
                      name="status"
                      as={TextField}
                      fullWidth
                      value={values.status}
                      onChange={handleChange}
                      error={touched.status && !!errors.status}
                      helperText={touched.status && errors.status}
                      sx={styledField}
                    />
                    <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>Purchase Date</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={values.purchase_date}
                        onChange={(value) => setFieldValue('purchase_date', value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={touched.purchase_date && !!errors.purchase_date}
                            helperText={touched.purchase_date && errors.purchase_date}
                            sx={styledField}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey' }}>Warranty Expiration</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={values.warranty_expiration}
                        onChange={(value) => setFieldValue('warranty_expiration', value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={touched.warranty_expiration && !!errors.warranty_expiration}
                            helperText={touched.warranty_expiration && errors.warranty_expiration}
                            sx={styledField}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Typography variant="h6" sx={{ fontWeight: 'bold', padding: '5px', color: 'grey', mt: 2 }}>Description*</Typography>
                <Field
                  name="description"
                  as={TextField}
                  label="Description*"
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  value={values.description}
                  onChange={handleChange}
                  error={touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                  sx={{ mt: 2, mb: 1 }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={onClose}
                  color="primary"
                  variant="outlined"
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    borderRadius: '12px',
                    borderWidth: '1px',
                    borderColor: 'action.active',
                    '&:hover': {
                      borderWidth: '1px',
                      borderColor: 'action.active',
                    },
                    mr: 1,
                  }}
                >
                  Discard
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disableElevation
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    color: 'common.white',
                    backgroundColor: 'primary.main',
                    borderRadius: '12px',
                    '&:hover': {
                      backgroundColor: 'primary.dark',
                    },
                  }}
                >
                  Save Changes
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default EditEquipmentDialog;
