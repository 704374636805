import React from 'react'
import {useSelector} from "react-redux"
import {Navigate, useLocation} from "react-router-dom"
import ChatBar from '../components/Messaging/Chatbar';

const ProtectedRoute = ({children}) => {
    const user = useSelector((state) => state.user.user);
    //console.log('7',user.user.access);
    if(!user.access) {
        return <Navigate to="/login"/>
    }
//  return children
    return (
        <>
            {children}
            {/* <ChatBar /> Include ChatBar here */}
        </>
    );

};

export default ProtectedRoute;