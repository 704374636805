import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import AdminPasswordAPIs from "../../utilities/api/AdminPasswordAPIs";
import SideBarComponent from "../../components/admin/login/SideBar";
import sideimage from "../../utilities/dummy_assets/commonlogin.png";
import EmailIcon from "@mui/icons-material/Email";

const typographyStyle = {
    color: "#5C5C5C",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "1.5rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.0165rem",
};
const boxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: ".6rem",
    alignSelf: "stretch",
};
const styledTextfield = {
    display: "flex",
    gap: "0.2rem",
    alignSelf: "stretch",
    borderRadius: "0.75rem",
};

const validation_schema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
});

const ForgotPasswordPage = () => {
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [submittedEmail, setSubmittedEmail] = useState("");
    const [timer, setTimer] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const storedExpirationTime = localStorage.getItem('resendTimerExpiration');
        if (storedExpirationTime) {
            const expirationTime = new Date(storedExpirationTime);
            const currentTime = new Date();
            const timeLeft = (expirationTime - currentTime) / 1000;
            if (timeLeft > 0) {
                setTimer(timeLeft);
                setEmailSubmitted(true);
            }
        }
    }, []);

    useEffect(() => {
        let countdown;
        if (timer > 0) {
            countdown = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        } else {
            localStorage.removeItem('resendTimerExpiration');
        }
        return () => clearInterval(countdown);
    }, [timer]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (timer > 0) {
                event.preventDefault();
                event.returnValue = 'You have a pending timer. Are you sure you want to leave?';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [timer]);

    const handleResend = () => {
        const expirationTime = new Date(new Date().getTime() + 60000);
        localStorage.setItem('resendTimerExpiration', expirationTime);
        setTimer(60);
        AdminPasswordAPIs.ForgotPassword({ email: submittedEmail })
            .then((res) => {
                setEmailSubmitted(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: validation_schema,
        onSubmit: (values) => {
            setSubmittedEmail(values.email);
            const expirationTime = new Date(new Date().getTime() + 60000);
            localStorage.setItem('resendTimerExpiration', expirationTime);
            setTimer(60);
            AdminPasswordAPIs.ForgotPassword(values)
                .then((res) => {
                    setEmailSubmitted(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    });

    return (
        <Box sx={{ display: "flex" }}>
            <SideBarComponent />
            <Box
                sx={{
                    width: "100%",
                    backgroundImage: `url(${sideimage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                {!emailSubmitted ? (
                    <Box
                        sx={{
                            display: "flex",
                            padding: "2rem",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "1rem",
                            borderRadius: "1rem",
                            background: "#FFF",
                            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                            width: "400px",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#212121",
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontSize: "1.75rem",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                letterSpacing: "-0.02475rem",
                            }}
                        >
                            Forgot Your Password?
                        </Typography>
                        <Typography
                            sx={{
                                color: "#5C5C5C",
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontSize: "1rem",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "1.5rem",
                                letterSpacing: "-0.0165rem",
                            }}
                        >
                            We will send you the instructions to the email address below to reset your password.
                        </Typography>
                        <Box
                            component="form"
                            onSubmit={formik.handleSubmit}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "1rem",
                                width: "100%",
                            }}
                        >
                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    Email Address
                                </Typography>
                                <TextField
                                    id="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.email &&
                                        Boolean(formik.errors.email)
                                    }
                                    helperText={
                                        formik.touched.email &&
                                        formik.errors.email
                                    }
                                    sx={styledTextfield}
                                    placeholder="enter email address"
                                    fullWidth
                                />
                            </Box>
                            <Button
                                type="submit"
                                sx={{
                                    display: "flex",
                                    padding: "0.75rem",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                    alignSelf: "stretch",
                                    borderRadius: "0.75rem",
                                    border: "1px solid #007BFF",
                                    background: "#007BFF",
                                    textTransform: "none",
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "#FFF",
                                        textAlign: "center",
                                        fontFamily: "Inter",
                                        fontSize: "1.25rem",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "normal",
                                        letterSpacing: "-0.01375rem",
                                    }}
                                >
                                    Reset my password
                                </Typography>
                            </Button>
                        </Box>

                        <Typography
                            onClick={() => navigate("/login")}
                            sx={{
                                fontSize: "1rem",
                                letterSpacing: "-0.01375rem",
                                color: "#007BFF",
                                cursor: "pointer",
                                marginTop: "1rem",
                            }}
                        >
                            Back to login
                        </Typography>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            padding: "2rem",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "1rem",
                            borderRadius: "1rem",
                            background: "#FFF",
                            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                            width: "400px",
                        }}
                    >
                        <EmailIcon sx={{ fontSize: 50, color: "rgb(30,110,178)" }} />
                        <Typography
                            sx={{
                                color: "#212121",
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontSize: "1.75rem",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                letterSpacing: "-0.02475rem",
                            }}
                        >
                            Check Your Email
                        </Typography>
                        <Typography
                            sx={{
                                color: "#5C5C5C",
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontSize: "1rem",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "1.5rem",
                                letterSpacing: "-0.0165rem",
                            }}
                        >
                            Please check the email address {submittedEmail} for instructions to reset your password.
                        </Typography>
                        <Button
                            onClick={handleResend}
                            disabled={timer > 0}
                            sx={{
                                display: "flex",
                                padding: "0.75rem",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "0.5rem",
                                alignSelf: "stretch",
                                borderRadius: "0.75rem",
                                border: `1px solid ${timer > 0 ? "#ccc" : "#007BFF"}`,
                                background: timer > 0 ? "#ccc" : "#FFF",
                                textTransform: "none",
                            }}
                        >
                            <Typography
                                sx={{
                                    color: timer > 0 ? "#666" : "#007BFF",
                                    textAlign: "center",
                                    fontFamily: "Inter",
                                    fontSize: "1.25rem",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01375rem",
                                }}
                            >
                                {timer > 0 ? `Resend in ${Math.ceil(timer)}s` : "Resend email"}
                            </Typography>
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default ForgotPasswordPage;

// import React, { useState } from "react";
// import { Box, Button, TextField, Typography } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import AdminPasswordAPIs from "utilities/api/AdminPasswordAPIs";
// import SideBarComponent from "../../components/auth/login/SideBar";
// import sideimage from "../../utilities/dummy_assets/commonlogin.png";
// import EmailIcon from "@mui/icons-material/Email";

// const typographyStyle = {
//     color: "#5C5C5C",
//     textAlign: "center",
//     fontFamily: "Inter",
//     fontSize: "1.5rem",
//     fontStyle: "normal",
//     fontWeight: 400,
//     lineHeight: "normal",
//     letterSpacing: "-0.0165rem",
// };
// const boxStyle = {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "flex-start",
//     gap: ".6rem",
//     alignSelf: "stretch",
// };
// const styledTextfield = {
//     display: "flex",
//     gap: "0.2rem",
//     alignSelf: "stretch",
//     borderRadius: "0.75rem",
// };

// const validation_schema = Yup.object({
//     email: Yup.string().email("Invalid email address").required("Required"),
// });

// const ForgotPasswordPage = () => {
//     const [emailSubmitted, setEmailSubmitted] = useState(false);
//     const [submittedEmail, setSubmittedEmail] = useState("");
//     const navigate = useNavigate();

//     const formik = useFormik({
//         initialValues: {
//             email: "",
//         },
//         validationSchema: validation_schema,
//         onSubmit: (values) => {
//             setSubmittedEmail(values.email);
//             // Call the ForgotPassword API
//             AdminPasswordAPIs.ForgotPassword(values)
//                 .then((res) => {
//                     setEmailSubmitted(true);
//                 })
//                 .catch((error) => {
//                     console.log(error);
//                 });
//         },
//     });

//     return (
//         <Box sx={{ display: "flex" }}>
//             <SideBarComponent />
//             <Box
//                 sx={{
//                     width: "100%",
//                     backgroundImage: `url(${sideimage})`,
//                     backgroundSize: "cover",
//                     backgroundRepeat: "no-repeat",
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     height: "100vh",
//                 }}
//             >
//                 {!emailSubmitted ? (
//                     <Box
//                         sx={{
//                             display: "flex",
//                             padding: "2rem",
//                             flexDirection: "column",
//                             alignItems: "center",
//                             gap: "1rem",
//                             borderRadius: "1rem",
//                             background: "#FFF",
//                             boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
//                             width: "400px",
//                         }}
//                     >
//                         <Typography
//                             sx={{
//                                 color: "#212121",
//                                 textAlign: "center",
//                                 fontFamily: "Inter",
//                                 fontSize: "1.75rem",
//                                 fontStyle: "normal",
//                                 fontWeight: 600,
//                                 lineHeight: "normal",
//                                 letterSpacing: "-0.02475rem",
//                             }}
//                         >
//                             Forgot Your Password?
//                         </Typography>
//                         <Typography
//                             sx={{
//                                 color: "#5C5C5C",
//                                 textAlign: "center",
//                                 fontFamily: "Inter",
//                                 fontSize: "1rem",
//                                 fontStyle: "normal",
//                                 fontWeight: 400,
//                                 lineHeight: "1.5rem",
//                                 letterSpacing: "-0.0165rem",
//                             }}
//                         >
//                             We will send you the instructions to the email address below to reset your password.
//                         </Typography>
//                         <Box
//                             component="form"
//                             onSubmit={formik.handleSubmit}
//                             sx={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                                 alignItems: "center",
//                                 gap: "1rem",
//                                 width: "100%",
//                             }}
//                         >
//                             <Box sx={boxStyle}>
//                                 <Typography sx={typographyStyle}>
//                                     Email Address
//                                 </Typography>
//                                 <TextField
//                                     id="email"
//                                     name="email"
//                                     value={formik.values.email}
//                                     onChange={formik.handleChange}
//                                     error={
//                                         formik.touched.email &&
//                                         Boolean(formik.errors.email)
//                                     }
//                                     helperText={
//                                         formik.touched.email &&
//                                         formik.errors.email
//                                     }
//                                     sx={styledTextfield}
//                                     placeholder="enter email address"
//                                     fullWidth
//                                 />
//                             </Box>
//                             <Button
//                                 type="submit"
//                                 sx={{
//                                     display: "flex",
//                                     padding: "0.75rem",
//                                     justifyContent: "center",
//                                     alignItems: "center",
//                                     gap: "0.5rem",
//                                     alignSelf: "stretch",
//                                     borderRadius: "0.75rem",
//                                     border: "1px solid #007BFF",
//                                     background: "#007BFF",
//                                     textTransform: "none",
//                                 }}
//                             >
//                                 <Typography
//                                     sx={{
//                                         color: "#FFF",
//                                         textAlign: "center",
//                                         fontFamily: "Inter",
//                                         fontSize: "1.25rem",
//                                         fontStyle: "normal",
//                                         fontWeight: 600,
//                                         lineHeight: "normal",
//                                         letterSpacing: "-0.01375rem",
//                                     }}
//                                 >
//                                     Reset my password
//                                 </Typography>
//                             </Button>
//                         </Box>

//                         <Typography
//                             onClick={() => navigate("/login")}
//                             sx={{
//                                 fontSize: "1rem",
//                                 letterSpacing: "-0.01375rem",
//                                 color: "#007BFF",
//                                 cursor: "pointer",
//                                 marginTop: "1rem",
//                             }}
//                         >
//                             Back to login
//                         </Typography>
//                     </Box>
//                 ) : (
//                     <Box
//                         sx={{
//                             display: "flex",
//                             padding: "2rem",
//                             flexDirection: "column",
//                             alignItems: "center",
//                             gap: "1rem",
//                             borderRadius: "1rem",
//                             background: "#FFF",
//                             boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
//                             width: "400px",
//                         }}
//                     >
//                         <EmailIcon sx={{ fontSize: 50, color: "rgb(30,110,178)" }} />
//                         <Typography
//                             sx={{
//                                 color: "#212121",
//                                 textAlign: "center",
//                                 fontFamily: "Inter",
//                                 fontSize: "1.75rem",
//                                 fontStyle: "normal",
//                                 fontWeight: 600,
//                                 lineHeight: "normal",
//                                 letterSpacing: "-0.02475rem",
//                             }}
//                         >
//                             Check Your Email
//                         </Typography>
//                         <Typography
//                             sx={{
//                                 color: "#5C5C5C",
//                                 textAlign: "center",
//                                 fontFamily: "Inter",
//                                 fontSize: "1rem",
//                                 fontStyle: "normal",
//                                 fontWeight: 400,
//                                 lineHeight: "1.5rem",
//                                 letterSpacing: "-0.0165rem",
//                             }}
//                         >
//                             Please check the email address {submittedEmail} for instructions to reset your password.
//                         </Typography>
//                         <Button
//                             onClick={() => formik.handleSubmit()}
//                             sx={{
//                                 display: "flex",
//                                 padding: "0.75rem",
//                                 justifyContent: "center",
//                                 alignItems: "center",
//                                 gap: "0.5rem",
//                                 alignSelf: "stretch",
//                                 borderRadius: "0.75rem",
//                                 border: "1px solid #007BFF",
//                                 background: "#FFF",
//                                 textTransform: "none",
//                             }}
//                         >
//                             <Typography
//                                 sx={{
//                                     color: "#007BFF",
//                                     textAlign: "center",
//                                     fontFamily: "Inter",
//                                     fontSize: "1.25rem",
//                                     fontStyle: "normal",
//                                     fontWeight: 600,
//                                     lineHeight: "normal",
//                                     letterSpacing: "-0.01375rem",
//                                 }}
//                             >
//                                 Resend email
//                             </Typography>
//                         </Button>
//                     </Box>
//                 )}
//             </Box>
//         </Box>
//     );
// };

// export default ForgotPasswordPage;
