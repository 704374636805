import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { AppRoutes } from './routes';
import ChatBar from './components/Messaging/Chatbar';
import { ToastContainer } from 'react-toastify';
import { useAxiosInterceptors } from './utilities/api/axios';
import Reportbutton from './components/reportButton/reportButton';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { useSelector } from 'react-redux';

function App() {
  // useAxiosInterceptors();
  const { user } = useSelector((state) => state.user.user); // Adjusted access to user state

  const logRocketAppId = process.env.REACT_APP_LOG_ROCKET_APP_ID;
  
  console.log(logRocketAppId);

  useEffect(() => {
    LogRocket.init(logRocketAppId);
    setupLogRocketReact(LogRocket);

    if (user) {
      LogRocket.identify(user.id, {
        name: user.username,
        email: user.email,
        is_staff: user.is_staff,
        is_student: user.is_student,
        is_active: user.is_active,
      });
    }
  },[user])

  return (
    <BrowserRouter>
      {/* <div className="App"> */}
        <AppRoutes />
        <Reportbutton/>
        {/* <ChatBar /> */}
        <ToastContainer />
      {/* </div> */}
    </BrowserRouter>
  );
}

export default App;


// import { BrowserRouter, Router } from 'react-router-dom';
// import './App.css';
// import { AppRoutes } from './routes';
// import { ToastContainer } from 'react-toastify';
// function App() {
//   return (
//     <BrowserRouter>
//       <AppRoutes/>
//     </BrowserRouter>
//   );
// }

// export default App;
