import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Avatar } from '@mui/material';
import { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import UserRequestAPIs from '../../../utilities/api/user/AdminUserRequestList';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const buttonStyle = {
  display: 'flex',
  padding: '0.5rem 0.75rem',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.5rem',
  borderRadius: '0.5rem',
};

const positionLabels = {
  0: 'TL student management body',
  1: 'Manager (other technical clubs)',
  2: 'TL Manager',
  3: 'TL Technician',
  4: 'Other',
};

const Request = () => {
  const [tableData, setTableData] = useState([]);
  const [acceptedRows, setAcceptedRows] = useState([]);
  const [rejectedRows, setRejectedRows] = useState([]);

  const user = useSelector((state) => state.user.user);

  const fetchAndFilterData = () => {
    const response = UserRequestAPIs.UserRequestListGet();
    response
      .then((result) => {
        const filteredData = result.filter((item) => item.TL_name === user.user.TL_name);
        setTableData(filteredData);
      })
      .catch((error) => {
        toast.error(`Error fetching data: ${error.message || 'Unknown error occurred'}`);
        console.log('error', error);
      });
  };

  useEffect(() => {
    fetchAndFilterData();
  }, [user]);

  const handleAccept = (row) => {
    setAcceptedRows([...acceptedRows, row]);
    setRejectedRows(rejectedRows.filter((rejectedRow) => rejectedRow !== row));

    const formData = new FormData();
    formData.append('accepted', true);
    formData.append('email', row.email);

    const response = UserRequestAPIs.AdminRequestAcceptDecline(formData);
    response
      .then((result) => {
        toast.success('Successfully accepted request!');
        fetchAndFilterData();
      })
      .catch((error) => {
        toast.error(`Error accepting request for ${row.email}: ${error.message || 'Unknown error occurred'}`);
        console.log('error', error);
      });
  };

  const handleReject = (row) => {
    setRejectedRows([...rejectedRows, row]);

    const formData = new FormData();
    formData.append('accepted', false);
    formData.append('email', row.email);

    const response = UserRequestAPIs.AdminRequestAcceptDecline(formData);
    response
      .then((result) => {
        toast.success('Declined request successfully!');
        fetchAndFilterData();
      })
      .catch((error) => {
        toast.error(`Error declining request for ${row.email}: ${error.message || 'Unknown error occurred'}`);
        console.log('error', error);
      });
  };

  const isAccepted = (row) => acceptedRows.includes(row);
  const isRejected = (row) => rejectedRows.includes(row);

  const columns = [
    {
      field: 'userName',
      headerName: 'User Name',
      flex: 2,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Avatar src={params.row.profile_pic} alt={params.row.name} />
          <div>
            <div>{params.row.name}</div>
            <div style={{ color: 'gray', fontSize: '0.875rem' }}>{params.row.email}</div>
          </div>
        </Box>
      ),
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1.5,
      renderCell: (params) => (
        <Box>
          {params.row.other_position ? (
            <div>{params.row.other_position || positionLabels[params.row.position]}</div>
          ) : (
            <div>{positionLabels[params.row.position]}</div>
          )}
        </Box>
      ),
    },
    {
      field: 'groupName',
      headerName: 'Group Name',
      flex: 1.5,
      renderCell: (params) => (
        <Box>
          <div>{params.row.TL_name}</div>
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '5rem',
            padding: '0.25rem 0.75rem',
            alignItems: 'center',
            gap: '0.25rem',
            borderBottom: '1px solid var(--Light-border, #F2F2F2)',
            background: 'var(--Light-background, #FFF)',
          }}
        >
          {!isRejected(params.row) && (
            <Button
              size="small"
              style={{
                ...buttonStyle,
                color: 'var(--Action-Success, #057D35)',
                '&:hover': {
                  backgroundColor: 'var(--Action-Success, #90ee90)',
                },
              }}
              onClick={() => handleAccept(params.row)}
              disabled={isAccepted(params.row)}
            >
              {isAccepted(params.row) ? (
                <>
                  Accepted <CheckCircleIcon style={{ marginLeft: '4px' }} />
                </>
              ) : (
                'Accept'
              )}
            </Button>
          )}
  
          {!isAccepted(params.row) && (
            <Button
              size="small"
              style={{
                ...buttonStyle,
                color: 'var(--Action-Error, #C90505)',
                '&:hover': {
                  backgroundColor: '#FF474C',
                },
              }}
              onClick={() => handleReject(params.row)}
              disabled={isRejected(params.row)}
            >
              {isRejected(params.row) ? (
                <>
                  Declined <CancelIcon style={{ marginLeft: '4px' }} />
                </>
              ) : (
                'Decline'
              )}
            </Button>
          )}
        </Box>
      ),
    },
  ];
  
  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={tableData.map((item, index) => ({ ...item, id: index }))}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
      <ToastContainer />
    </div>
  );
};

export default Request;
