// websocketService.js
class WebSocketService {
    constructor() {
        this.ws = null;
    }

    connect(receiverUsername, onMessageReceived, onClose, onError) {
        if (this.ws) {
            this.disconnect();
        }

        this.ws = new WebSocket(`ws://staging.misadmin.mapit.ai/ws/chat/${receiverUsername}/`);

        this.ws.onmessage = (event) => {
            const receivedMessage = JSON.parse(event.data);
            onMessageReceived(receivedMessage.message);
        };

        this.ws.onclose = onClose;
        this.ws.onerror = onError;
    }

    sendMessage(message) {
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
            this.ws.send(JSON.stringify(message));
        } else {
            console.error("WebSocket is not open.");
        }
    }

    disconnect() {
        if (this.ws) {
            this.ws.close();
            this.ws = null;
        }
    }
}

const webSocketService = new WebSocketService();
export default webSocketService;
