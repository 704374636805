import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Avatar, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useState, useEffect } from 'react';
import AdminUserList from '../../../utilities/api/user/AdminUserList';
import { useSelector } from 'react-redux';
import { ImageBaseURL } from "../../../utilities/api/axios";

const positionLabels = {
  0: 'TL student management body',
  1: 'Manager (other technical clubs)',
  2: 'TL Manager',
  3: 'TL Technician',
  4: 'Other',
};

const columns = [
  {
    field: 'name',
    headerName: 'User Name',
    flex: 1,
    renderCell: (params) => (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Avatar src={`${ImageBaseURL}${params.row.image}`} alt={params.row.name} />
        <div>
          <div>{params.value}</div>
          <div style={{ color: 'gray', fontSize: '0.875rem' }}>{params.row.email}</div>
        </div>
      </Box>
    ),
  },
  { field: 'role', headerName: 'Role', flex: 0.5 },
  { field: 'group', headerName: 'Group Name', flex: 0.5 },
  {
    field: 'actions',
    headerName: 'Action',
    flex: 0.5,
    renderCell: (params) => (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <IconButton onClick={() => console.log('Delete', params.id)}>
          <DeleteIcon color="error" />
        </IconButton>
        <IconButton onClick={() => console.log('Edit', params.id)}>
          <EditIcon color="primary" />
        </IconButton>
      </Box>
    ),
  },
];

const User = () => {
  const [rows, setRows] = useState([]);
  const user = useSelector(state => state.user.user);
  const currentUserCollegeName = user?.user?.TL_name;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await AdminUserList.AllUserListGet();
        const formattedData = data
          .filter(user => user.TL_name === currentUserCollegeName) // Filter users by TL_name
          .map(user => ({
            id: user.id,
            name: user.username,
            email: user.email,
            role: positionLabels[user.position] || 'Unknown', // Map user.position to positionLabels
            group: user.TL_name,
            image: user.profile_pic,
          }));
        setRows(formattedData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [user]); // Add currentUserCollegeName as a dependency if it can change

  console.log('rows', rows);

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
    </Box>
  );
};

export default User;
