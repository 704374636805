import React from 'react';
import { useFormik } from 'formik';
import {
  TextField,
  Button,
  Grid,
  Box,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import expandleft from '../../../utilities/dummy_assets/Expand_left.png';
import Auth_APIs from '../../../utilities/api/auth_api';

const buttonStyle = {
  display: 'flex',
  width: '25rem',
  padding: '1rem 1.5rem',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.5rem',
  borderRadius: '0.75rem',
  background: 'var(--Accent-Accent-00, #007BFF)',
};

const typographyStyle = {
  color: 'var(--Primary-white, #FFF)',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '1.2rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '-0.0165rem',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone_number: Yup.string().required('Phone number is required'),
  other_position: Yup.string(),
});

const OrganizationalRegistrationForm = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone_number: '',
      other_position: '',
      TL: 'Org-Admin',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await Auth_APIs.orgAdminregister(values);
        toast.success('Successfully registered');

        setTimeout(() => {
          navigate('/login');
        }, 1500); // Delay navigation
      } catch (error) {
        if (error.response && error.response.data) {
          const errorMessage = Object.values(error.response.data).flat().join(' ');
          toast.error(errorMessage);
        } else {
          toast.error('Registration failed. Please try again.');
        }
      }
    },
  });

  return (
    <Box
      sx={{
        width: '50rem',
        height: '30rem',
        mx: 'auto',
        p: 2,
        backgroundColor: 'transparent',
      }}
    >
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={6} gap={15}>
            <Typography variant="h6">User Name</Typography>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Enter user name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              margin="normal"
            />
            <Typography variant="h6">Phone No.</Typography>
            <TextField
              fullWidth
              id="phone_number"
              name="phone_number"
              label="Enter phone no."
              value={formik.values.phone_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
              helperText={formik.touched.phone_number && formik.errors.phone_number}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">User Email Address</Typography>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Enter user email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              margin="normal"
            />
            <Typography variant="h6">Role</Typography>
            <TextField
              fullWidth
              id="other_position"
              name="other_position"
              label="Enter Role"
              value={formik.values.other_position}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.other_position && Boolean(formik.errors.other_position)}
              helperText={formik.touched.other_position && formik.errors.other_position}
              margin="normal"
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', alignSelf: 'stretch', gap: '2rem', marginTop: '20px' }}>
          <Button
            variant="outlined"
            onClick={() => navigate('/login')}
            sx={{
              display: 'flex',
              width: '25rem',
              padding: '1rem',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '0.5rem',
              borderRadius: '0.75rem',
              background: '1px solid var(--Primary-Primary03, #5C5C5C)',
            }}
          >
            <Typography sx={typographyStyle && { color: 'black' }}>Cancel</Typography>
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={buttonStyle}
          >
            <Typography sx={typographyStyle}>Register</Typography>
          </Button>
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'stretch',
          paddingTop: '.5rem'
        }}>
          <img
            src={expandleft}
            onClick={() => navigate('/login')}
            style={{ cursor: 'pointer' }}
          />
          <Typography
            onClick={() => navigate('/login')}
            sx={typographyStyle && { fontSize: '1rem', letterSpacing: '-0.01375rem', color: 'var(--Accent-Accent-00, #007BFF)', cursor: 'pointer' }}
          >
            Back
          </Typography>
          <Typography sx={typographyStyle && { fontSize: '1rem', letterSpacing: '-0.01375rem', marginLeft: '30.2rem' }}>
            Already have an account? <span onClick={() => navigate('/login')} style={{ color: 'var(--Accent-Accent-01, #0062CC)', cursor: 'pointer' }}>Log in here</span>
          </Typography>
        </Box>
      </form>
    </Box>
  );
};

export default OrganizationalRegistrationForm;
