import axios from 'axios';
import { store } from '../../redux/store'; // adjust the path to your store if necessary
import { getUserFail, updateTokens } from '../../redux/slices/userSlice'; // adjust the path to your slices if necessary

const axiosAuthService = axios.create({
    baseURL: 'https://sys.demo.mis.mapit.ai/api',
    // COMMENTED TO MAKE TOKEN REFRESHING LOGIC WORK
    // headers: {
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    // },
});

const axiosApiService = axios.create({
    baseURL: 'https://sys.demo.mis.mapit.ai/api',
});

const ImageBaseURL = 'https://sys.demo.mis.mapit.ai';

const refreshToken = async (refreshToken) => {
    try {
        const response = await axiosAuthService.post('/auth/refresh/', { refresh: refreshToken });
        return response.data;
    } catch (error) {
        console.error('Error refreshing token:', error.response?.data || error.message);
        throw error;
    }
};

const setupInterceptors = () => {
    const requestInterceptor = (config) => {
        const state = store.getState();
        const user = state.user.user;

        if (user?.access) {
            config.headers.Authorization = `Bearer ${user.access}`;
        }

        // if (user?.user?.TL_name) {
        //     config.headers.TL = user.user.TL_name;
        // }
        
        // Only set the TL header if it does not already exist
        if (user?.user?.TL_name && !config.headers.TL) {
            config.headers.TL = user.user.TL_name;
        }

        return config;
    };

    const responseErrorInterceptor = async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const state = store.getState();
                const user = state.user.user;
                const newTokens = await refreshToken(user.refresh);
                console.log('newTokens',newTokens);
                store.dispatch(updateTokens({ access: newTokens.access, refresh: user.refresh }));
                originalRequest.headers.Authorization = `Bearer ${newTokens.access}`;
                return axios(originalRequest);
            } catch (refreshError) {
                store.dispatch(getUserFail(refreshError.response?.data || refreshError.message));
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    };

    axiosAuthService.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));
    axiosAuthService.interceptors.response.use(
        (response) => response,
        responseErrorInterceptor
    );

    axiosApiService.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));
    axiosApiService.interceptors.response.use(
        (response) => response,
        responseErrorInterceptor
    );
};

// Set up the interceptors
setupInterceptors();

export default axiosAuthService;
export { axiosApiService, ImageBaseURL};



// import axios from "axios";
// import { useSelector, useDispatch } from "react-redux";
// import { useEffect } from "react";
// import { getUserSuccess, getUserFail, updateTokens } from '../../redux/slices/userSlice';

// const axiosAuthService = axios.create({
//     baseURL: "https://staging.misadmin.mapit.ai/api",
//     headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "*",
//         "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
//     },
// });

// export const axiosApiService = axios.create({
//     baseURL: "https://staging.misadmin.mapit.ai/api",
// });

// const refreshToken = async (refreshToken) => {
//     try {
//         console.log('refreshToken',refreshToken);
//         const response = await axiosAuthService.post('/auth/refresh/', { refresh: refreshToken });
//         console.log('refreshToken Response',response);
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };

// const useAxiosInterceptors = () => {
//     const user = useSelector(state => state.user.user);
//     const dispatch = useDispatch();

//     useEffect(() => {
//         const requestInterceptor = async (config) => {
//             const token = user?.access;

//             if (token) {
//                 config.headers.Authorization = "Bearer " + token;
//                 console.debug(
//                     "[Request] Authorization header added:",
//                     config.headers.Authorization
//                 );
//             }

//             if (user?.user?.college_name) {
//                 config.headers.college = user?.user?.college_name;
//                 console.debug("[Request] College header added:", config.headers.college);
//             } else {
//                 console.debug("[Request] College header not added, user.college is:", user?.user?.college_name);
//             }

//             return config;
//         };
// export const axiosApiService = axios.create({
//     baseURL: "https://staging.misadmin.mapit.ai/api",
// });

// const refreshToken = async (refreshToken) => {
//     try {
//         console.log('refreshToken',refreshToken);
//         const response = await axiosAuthService.post('/auth/refresh/', { refresh: refreshToken });
//         console.log('refreshToken Response',response);
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };

// const useAxiosInterceptors = () => {
//     const user = useSelector(state => state.user.user);
//     const dispatch = useDispatch();

//     useEffect(() => {
//         const requestInterceptor = async (config) => {
//             const token = user?.access;

//             if (token) {
//                 config.headers.Authorization = "Bearer " + token;
//                 console.debug(
//                     "[Request] Authorization header added:",
//                     config.headers.Authorization
//                 );
//             }

//             if (user?.user?.college_name) {
//                 config.headers.college = user?.user?.college_name;
//                 console.debug("[Request] College header added:", config.headers.college);
//             } else {
//                 console.debug("[Request] College header not added, user.college is:", user?.user?.college_name);
//             }

//             return config;
//         };

//         const responseInterceptor = (res) => {
//             console.debug(
//                 "[Response]",
//                 res.config.baseURL + res.config.url,
//                 res.status,
//                 res.data
//             );
//             return Promise.resolve(res);
//         };

//         const responseErrorInterceptor = async (error) => {
//             const originalRequest = error.config;
//             if (error.response.status === 401 && !originalRequest._retry) {
//                 originalRequest._retry = true;
//                 try {
//                     const newTokens = await refreshToken(user.refresh);
//                     dispatch(updateTokens({ access: newTokens.access, refresh: user.refresh }));
//                     originalRequest.headers.Authorization = 'Bearer ' + newTokens.access;
//                     return axiosAuthService(originalRequest);
//                 } catch (refreshError) {
//                     dispatch(getUserFail(refreshError.response.data));
//                     return Promise.reject(refreshError);
//                 }
//             }
//             console.debug(
//                 "[Response]",
//                 error.config.baseURL + error.config.url,
//                 error.response.status,
//                 error.response.data
//             );
//             return Promise.reject(error);
//         };
//         const responseInterceptor = (res) => {
//             console.debug(
//                 "[Response]",
//                 res.config.baseURL + res.config.url,
//                 res.status,
//                 res.data
//             );
//             return Promise.resolve(res);
//         };

//         const responseErrorInterceptor = async (error) => {
//             const originalRequest = error.config;
//             if (error.response.status === 401 && !originalRequest._retry) {
//                 originalRequest._retry = true;
//                 try {
//                     const newTokens = await refreshToken(user.refresh);
//                     dispatch(updateTokens({ access: newTokens.access, refresh: user.refresh }));
//                     originalRequest.headers.Authorization = 'Bearer ' + newTokens.access;
//                     return axiosAuthService(originalRequest);
//                 } catch (refreshError) {
//                     dispatch(getUserFail(refreshError.response.data));
//                     return Promise.reject(refreshError);
//                 }
//             }
//             console.debug(
//                 "[Response]",
//                 error.config.baseURL + error.config.url,
//                 error.response.status,
//                 error.response.data
//             );
//             return Promise.reject(error);
//         };

//         const reqInterceptorId = axiosAuthService.interceptors.request.use(requestInterceptor);
//         const resInterceptorId = axiosAuthService.interceptors.response.use(
//             responseInterceptor,
//             responseErrorInterceptor
//         );
//         const reqInterceptorId = axiosAuthService.interceptors.request.use(requestInterceptor);
//         const resInterceptorId = axiosAuthService.interceptors.response.use(
//             responseInterceptor,
//             responseErrorInterceptor
//         );

//         const reqInterceptorIdApi = axiosApiService.interceptors.request.use(requestInterceptor);
//         const resInterceptorIdApi = axiosApiService.interceptors.response.use(
//             responseInterceptor,
//             responseErrorInterceptor
//         );
//         const reqInterceptorIdApi = axiosApiService.interceptors.request.use(requestInterceptor);
//         const resInterceptorIdApi = axiosApiService.interceptors.response.use(
//             responseInterceptor,
//             responseErrorInterceptor
//         );

//         // Cleanup interceptors on unmount
//         return () => {
//             axiosAuthService.interceptors.request.eject(reqInterceptorId);
//             axiosAuthService.interceptors.response.eject(resInterceptorId);
//             axiosApiService.interceptors.request.eject(reqInterceptorIdApi);
//             axiosApiService.interceptors.response.eject(resInterceptorIdApi);
//         };
//     }, [user, dispatch]);
// };

// export function fetcher(url) {
//     return axiosAuthService.get(url).then((res) => res.data);
// }

// export default axiosAuthService;
// export { useAxiosInterceptors };




// import axios from "axios";
// import { useSelector } from "react-redux";
// import { useEffect } from "react";

// const axiosAuthService = axios.create({
//     baseURL: "https://staging.misadmin.mapit.ai/api",
//     // baseURL: "http://staging.misadmin.mapit.ai/api",
//     headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "*",
//         "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
//     },
// });

// export const axiosApiService = axios.create({
//     baseURL: "https://staging.misadmin.mapit.ai/api",
//     // baseURL: "http://staging.misadmin.mapit.ai/api",
// });

// const useAxiosInterceptors = () => {
//     const user = useSelector(state => state.user.user);

//     useEffect(() => {
//         const requestInterceptor = async (config) => {
//             const token = user?.access;

//             if (token) {
//                 config.headers.Authorization = "Bearer " + token;
//                 console.debug(
//                     "[Request] Authorization header added:",
//                     config.headers.Authorization
//                 );
//             }

//             // if (user?.user?.college) {
//             //     config.headers.college = user?.user?.college;
//             //     console.debug("[Request] College header added:", config.headers.college);
//             // } else {
//             //     console.debug("[Request] College header not added, user.college is:", user?.user?.college);
//             // }
//             if (user?.user?.college_name) {
//                 config.headers.college = user?.user?.college_name;
//                 console.debug("[Request] College header added:", config.headers.college);
//             } else {
//                 console.debug("[Request] College header not added, user.college is:", user?.user?.college_name);
//             }

//             return config;
//         };

//         const responseInterceptor = (res) => {
//             console.debug(
//                 "[Response]",
//                 res.config.baseURL + res.config.url,
//                 res.status,
//                 res.data
//             );
//             return Promise.resolve(res);
//         };

//         const responseErrorInterceptor = (err) => {
//             console.log(err);
//             console.debug(
//                 "[Response]",
//                 err.config.baseURL + err.config.url,
//                 err.response.status,
//                 err.response.data
//             );
//             return Promise.reject(err);
//         };

//         const reqInterceptorId = axiosAuthService.interceptors.request.use(requestInterceptor);
//         const resInterceptorId = axiosAuthService.interceptors.response.use(
//             responseInterceptor,
//             responseErrorInterceptor
//         );

//         const reqInterceptorIdApi = axiosApiService.interceptors.request.use(requestInterceptor);
//         const resInterceptorIdApi = axiosApiService.interceptors.response.use(
//             responseInterceptor,
//             responseErrorInterceptor
//         );

//         // Cleanup interceptors on unmount
//         return () => {
//             axiosAuthService.interceptors.request.eject(reqInterceptorId);
//             axiosAuthService.interceptors.response.eject(resInterceptorId);
//             axiosApiService.interceptors.request.eject(reqInterceptorIdApi);
//             axiosApiService.interceptors.response.eject(resInterceptorIdApi);
//         };
//     }, [user]);
// };
//         // Cleanup interceptors on unmount
//         return () => {
//             axiosAuthService.interceptors.request.eject(reqInterceptorId);
//             axiosAuthService.interceptors.response.eject(resInterceptorId);
//             axiosApiService.interceptors.request.eject(reqInterceptorIdApi);
//             axiosApiService.interceptors.response.eject(resInterceptorIdApi);
//         };
//     }, [user, dispatch]);
// };

// export function fetcher(url) {
//     return axiosAuthService.get(url).then((res) => res.data);
// }

// export default axiosAuthService;
// export { useAxiosInterceptors };




// import axios from "axios";
// import { useSelector } from "react-redux";
// import { useEffect } from "react";

// const axiosAuthService = axios.create({
//     baseURL: "https://staging.misadmin.mapit.ai/api",
//     // baseURL: "http://staging.misadmin.mapit.ai/api",
//     headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "*",
//         "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
//     },
// });

// export const axiosApiService = axios.create({
//     baseURL: "https://staging.misadmin.mapit.ai/api",
//     // baseURL: "http://staging.misadmin.mapit.ai/api",
// });

// const useAxiosInterceptors = () => {
//     const user = useSelector(state => state.user.user);

//     useEffect(() => {
//         const requestInterceptor = async (config) => {
//             const token = user?.access;

//             if (token) {
//                 config.headers.Authorization = "Bearer " + token;
//                 console.debug(
//                     "[Request] Authorization header added:",
//                     config.headers.Authorization
//                 );
//             }

//             // if (user?.user?.college) {
//             //     config.headers.college = user?.user?.college;
//             //     console.debug("[Request] College header added:", config.headers.college);
//             // } else {
//             //     console.debug("[Request] College header not added, user.college is:", user?.user?.college);
//             // }
//             if (user?.user?.college_name) {
//                 config.headers.college = user?.user?.college_name;
//                 console.debug("[Request] College header added:", config.headers.college);
//             } else {
//                 console.debug("[Request] College header not added, user.college is:", user?.user?.college_name);
//             }

//             return config;
//         };

//         const responseInterceptor = (res) => {
//             console.debug(
//                 "[Response]",
//                 res.config.baseURL + res.config.url,
//                 res.status,
//                 res.data
//             );
//             return Promise.resolve(res);
//         };

//         const responseErrorInterceptor = (err) => {
//             console.log(err);
//             console.debug(
//                 "[Response]",
//                 err.config.baseURL + err.config.url,
//                 err.response.status,
//                 err.response.data
//             );
//             return Promise.reject(err);
//         };

//         const reqInterceptorId = axiosAuthService.interceptors.request.use(requestInterceptor);
//         const resInterceptorId = axiosAuthService.interceptors.response.use(
//             responseInterceptor,
//             responseErrorInterceptor
//         );

//         const reqInterceptorIdApi = axiosApiService.interceptors.request.use(requestInterceptor);
//         const resInterceptorIdApi = axiosApiService.interceptors.response.use(
//             responseInterceptor,
//             responseErrorInterceptor
//         );

//         // Cleanup interceptors on unmount
//         return () => {
//             axiosAuthService.interceptors.request.eject(reqInterceptorId);
//             axiosAuthService.interceptors.response.eject(resInterceptorId);
//             axiosApiService.interceptors.request.eject(reqInterceptorIdApi);
//             axiosApiService.interceptors.response.eject(resInterceptorIdApi);
//         };
//     }, [user]);
// };

// export function fetcher(url) {
//     return axiosAuthService.get(url).then((res) => res.data);
// }

// export default axiosAuthService;
// export { useAxiosInterceptors };



// import axios from "axios";
// import { useSelector } from "react-redux";
// import { useEffect } from "react";

// const axiosAuthService = axios.create({
//     baseURL: "https://staging.misadmin.mapit.ai/api",
//     headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "*",
//         "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
//     },
// export function fetcher(url) {
//     return axiosAuthService.get(url).then((res) => res.data);
// }

// export default axiosAuthService;
// export { useAxiosInterceptors };



// import axios from "axios";
// import { useSelector } from "react-redux";
// import { useEffect } from "react";

// const axiosAuthService = axios.create({
//     baseURL: "https://staging.misadmin.mapit.ai/api",
//     headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "*",
//         "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
//     },
// });

// export const axiosApiService = axios.create({
//     baseURL: "https://staging.misadmin.mapit.ai/api",
// export const axiosApiService = axios.create({
//     baseURL: "https://staging.misadmin.mapit.ai/api",
// });

// const useAxiosInterceptors = () => {
//     const user = useSelector(state => state.user.user);

//     useEffect(() => {
//         const requestInterceptor = async (config) => {
//             const token = user?.access;

//             if (token) {
//                 config.headers.Authorization = "Bearer " + token;
//                 console.debug(
//                     "[Request]",
//                     config.baseURL + config.url,
//                     JSON.stringify(token)
//                 );
//             }
//             return config;
//         };

//         const responseInterceptor = (res) => {
//             console.debug(
//                 "[Response]",
//                 res.config.baseURL + res.config.url,
//                 res.status,
//                 res.data
//             );
//             return Promise.resolve(res);
//         };

//         const responseErrorInterceptor = (err) => {
//             console.log(err);
//             console.debug(
//                 "[Response]",
//                 err.config.baseURL + err.config.url,
//                 err.response.status,
//                 err.response.data
//             );
//             return Promise.reject(err);
//         };

//         const reqInterceptorId = axiosAuthService.interceptors.request.use(requestInterceptor);
//         const resInterceptorId = axiosAuthService.interceptors.response.use(
//             responseInterceptor,
//             responseErrorInterceptor
//         );

//         const reqInterceptorIdApi = axiosApiService.interceptors.request.use(requestInterceptor);
//         const resInterceptorIdApi = axiosApiService.interceptors.response.use(
//             responseInterceptor,
//             responseErrorInterceptor
//         );

//         // Cleanup interceptors on unmount
//         return () => {
//             axiosAuthService.interceptors.request.eject(reqInterceptorId);
//             axiosAuthService.interceptors.response.eject(resInterceptorId);
//             axiosApiService.interceptors.request.eject(reqInterceptorIdApi);
//             axiosApiService.interceptors.response.eject(resInterceptorIdApi);
//         };
//     }, [user]);
// };

// const useAxiosInterceptors = () => {
//     const user = useSelector(state => state.user.user);

//     useEffect(() => {
//         const requestInterceptor = async (config) => {
//             const token = user?.access;

//             if (token) {
//                 config.headers.Authorization = "Bearer " + token;
//                 console.debug(
//                     "[Request]",
//                     config.baseURL + config.url,
//                     JSON.stringify(token)
//                 );
//             }
//             return config;
//         };

//         const responseInterceptor = (res) => {
//             console.debug(
//                 "[Response]",
//                 res.config.baseURL + res.config.url,
//                 res.status,
//                 res.data
//             );
//             return Promise.resolve(res);
//         };

//         const responseErrorInterceptor = (err) => {
//             console.log(err);
//             console.debug(
//                 "[Response]",
//                 err.config.baseURL + err.config.url,
//                 err.response.status,
//                 err.response.data
//             );
//             return Promise.reject(err);
//         };

//         const reqInterceptorId = axiosAuthService.interceptors.request.use(requestInterceptor);
//         const resInterceptorId = axiosAuthService.interceptors.response.use(
//             responseInterceptor,
//             responseErrorInterceptor
//         );

//         const reqInterceptorIdApi = axiosApiService.interceptors.request.use(requestInterceptor);
//         const resInterceptorIdApi = axiosApiService.interceptors.response.use(
//             responseInterceptor,
//             responseErrorInterceptor
//         );

//         // Cleanup interceptors on unmount
//         return () => {
//             axiosAuthService.interceptors.request.eject(reqInterceptorId);
//             axiosAuthService.interceptors.response.eject(resInterceptorId);
//             axiosApiService.interceptors.request.eject(reqInterceptorIdApi);
//             axiosApiService.interceptors.response.eject(resInterceptorIdApi);
//         };
//     }, [user]);
// };

// export function fetcher(url) {
//     return axiosAuthService.get(url).then((res) => res.data);
// }

// export default axiosAuthService;
// export { useAxiosInterceptors };




// import axios from "axios";
// // import createAuthRefreshInterceptor from "axios-auth-refresh";
// //import store from "../store/store";
// //import authSlice from "../store/authSlice";

// const axiosAuthService = axios.create({
//     baseURL: "https://staging.misadmin.mapit.ai/api",
//     headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "*",
//         "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"    
//     },
// });

// export const axiosApiService = axios.create({
//     baseURL: "https://staging.misadmin.mapit.ai/api",
    
// });

// // const requestInterceptor = async (config) => {
// //     const { token } = store.getState().persistedAuthReducer.auth;

// //     if (token !== null && token !== "") {
// //         config.headers.Authorization = "Bearer " + token;
// //         console.debug(
// //             "[Request]",
// //             config.baseURL + config.url,
// //             JSON.stringify(token)
// //         );
// //     }
// //     return config;
// // };

// // const responseInterceptor = (res) => {
//     // console.debug(
//     //     "[Response]",
//     //     res.config.baseURL + res.config.url,
//     //     res.status,
//     //     res.data
//     // );
//     // res.headers['Access-Control-Allow-Origin'] = 'http://localhost:3000';
// //     return Promise.resolve(res);
// // };

// // const responseErrorInterceptor = (err) => {
// //     console.log(err);
// //     console.debug(
// //         "[Response]",
// //         err.config.baseURL + err.config.url,
// //         err.response.status,
// //         err.response.data
// //     );
// //     return Promise.reject(err);
// // };

// // axiosAuthService.interceptors.request.use(requestInterceptor);

// // axiosAuthService.interceptors.response.use(
// //     responseInterceptor,
// //     responseErrorInterceptor
// // );

// //axiosApiService.interceptors.request.use(requestInterceptor);

// // axiosApiService.interceptors.response.use(
// //     responseInterceptor,
// //     responseErrorInterceptor
// // );

// // const refreshAuthLogic = async (failedRequest) => {
// //     const refreshToken =
// //         store.getState().persistedAuthReducer.auth.refreshToken;
// //     if (refreshToken !== null && refreshToken !== "") {
// //         console.log(refreshToken);
// //         return axios
// //             .post(`${process.env.REACT_APP_AUTH_API_URL}/auth/refresh/`, {
// //                 refresh: refreshToken,
// //             })
// //             .then((resp) => {
// //                 const { access } = resp.data;
// //                 failedRequest.response.config.headers.Authorization =
// //                     "Bearer " + access;
// //                 console.log(resp);
// //                 store.dispatch(
// //                     authSlice.actions.setAccessToken({
// //                         token: access,
// //                     })
// //                 );
// //             })
// //             .catch((err) => {
// //                 if (err.response && err.response.status === 401) {
// //                     store.dispatch(authSlice.actions.setLogout());
// //                 }
// //                 console.log(err);
// //             });
// //     }
// // };

// // createAuthRefreshInterceptor(axiosAuthService, refreshAuthLogic, {
// //     statusCodes: [401],
// // });

// // createAuthRefreshInterceptor(axiosApiService, refreshAuthLogic, {
// //     statusCodes: [401],
// // });

// // export function fetcher(url) {
// //     return axiosAuthService.get(url).then((res) => res.data);
// // }

// export default axiosAuthService;
// export default axiosAuthService;
// export { useAxiosInterceptors };




// import axios from "axios";
// // import createAuthRefreshInterceptor from "axios-auth-refresh";
// //import store from "../store/store";
// //import authSlice from "../store/authSlice";

// const axiosAuthService = axios.create({
//     baseURL: "https://staging.misadmin.mapit.ai/api",
//     headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "*",
//         "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"    
//     },
// });

// export const axiosApiService = axios.create({
//     baseURL: "https://staging.misadmin.mapit.ai/api",
    
// });

// // const requestInterceptor = async (config) => {
// //     const { token } = store.getState().persistedAuthReducer.auth;

// //     if (token !== null && token !== "") {
// //         config.headers.Authorization = "Bearer " + token;
// //         console.debug(
// //             "[Request]",
// //             config.baseURL + config.url,
// //             JSON.stringify(token)
// //         );
// //     }
// //     return config;
// // };

// // const responseInterceptor = (res) => {
//     // console.debug(
//     //     "[Response]",
//     //     res.config.baseURL + res.config.url,
//     //     res.status,
//     //     res.data
//     // );
//     // res.headers['Access-Control-Allow-Origin'] = 'http://localhost:3000';
// //     return Promise.resolve(res);
// // };

// // const responseErrorInterceptor = (err) => {
// //     console.log(err);
// //     console.debug(
// //         "[Response]",
// //         err.config.baseURL + err.config.url,
// //         err.response.status,
// //         err.response.data
// //     );
// //     return Promise.reject(err);
// // };

// // axiosAuthService.interceptors.request.use(requestInterceptor);

// // axiosAuthService.interceptors.response.use(
// //     responseInterceptor,
// //     responseErrorInterceptor
// // );

// //axiosApiService.interceptors.request.use(requestInterceptor);

// // axiosApiService.interceptors.response.use(
// //     responseInterceptor,
// //     responseErrorInterceptor
// // );

// // const refreshAuthLogic = async (failedRequest) => {
// //     const refreshToken =
// //         store.getState().persistedAuthReducer.auth.refreshToken;
// //     if (refreshToken !== null && refreshToken !== "") {
// //         console.log(refreshToken);
// //         return axios
// //             .post(`${process.env.REACT_APP_AUTH_API_URL}/auth/refresh/`, {
// //                 refresh: refreshToken,
// //             })
// //             .then((resp) => {
// //                 const { access } = resp.data;
// //                 failedRequest.response.config.headers.Authorization =
// //                     "Bearer " + access;
// //                 console.log(resp);
// //                 store.dispatch(
// //                     authSlice.actions.setAccessToken({
// //                         token: access,
// //                     })
// //                 );
// //             })
// //             .catch((err) => {
// //                 if (err.response && err.response.status === 401) {
// //                     store.dispatch(authSlice.actions.setLogout());
// //                 }
// //                 console.log(err);
// //             });
// //     }
// // };

// // createAuthRefreshInterceptor(axiosAuthService, refreshAuthLogic, {
// //     statusCodes: [401],
// // });

// // createAuthRefreshInterceptor(axiosApiService, refreshAuthLogic, {
// //     statusCodes: [401],
// // });

// // export function fetcher(url) {
// //     return axiosAuthService.get(url).then((res) => res.data);
// // }

// export default axiosAuthService;
