import React from 'react';
import {
  TextField,
  Button,
  MenuItem,
  Grid,
  Box,
  Typography,
  FormControl,
  Select,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import AdminUserDetail from '../../utilities/api/user/AdminUserDetail';
import { updateUser } from '../../redux/slices/userSlice';

const CollegeInfo = () => {
  const { user } = useSelector((state) => state.user.user);
  const dispatch = useDispatch(); // Access the Redux dispatch function

  const useStyles = () => ({
    formContainer: {
      border: '1px solid #7A7A7A',
      borderRadius: '4px',
      padding: '1rem',
      margin: 'auto',
    },
    submitButton: {
      marginTop: '1rem',
    },
  });

  const classes = useStyles();

  const [editMode, setEditMode] = React.useState(false);

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  const handleSave = async (values) => {
    try {
      const updatedUser = await AdminUserDetail.AdminDetailUpdate(user.id, values);
      console.log('User updated successfully:', updatedUser);
      dispatch(updateUser(updatedUser)); // Dispatch the updateUser action with the updated user data
      setEditMode(false);  // Exit edit mode
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const title_styles = {
    color: 'rgb(122, 122, 122)',
    marginBottom: '3px',
    fontSize: '1.4rem',
  };

  const degree_options = ['B.Tech', 'M.Tech', 'M.Sc', 'PhD', 'MCA', 'MBA', 'Other'];
  const department_options = [
    ['CSE', 'Computer Science and Engineering'],
    ['ECE', 'Electronics and Communication Engineering'],
    ['ME', 'Mechanical Engineering'],
    ['CE', 'Civil Engineering'],
    ['EE', 'Electrical Engineering'],
    ['CHE', 'Chemical Engineering'],
    ['MME', 'Metallurgical and Materials Engineering'],
    ['BT', 'Biotechnology'],
    ['CL', 'Chemistry'],
    ['MA', 'Mathematics'],
    ['PH', 'Physics'],
    ['HSS', 'Humanities and Social Sciences'],
    ['Other', 'Other'],
  ];

  const current_year_options = [
    { value: 1, label: 'Fresher' },
    { value: 2, label: 'Sophomore' },
    { value: 3, label: 'Third' },
    { value: 4, label: 'Fourth' },
    { value: 5, label: 'Fifth' },
  ];

  const validationSchema = Yup.object().shape({
    department: Yup.string().required('Department is required'),
    degree: Yup.string().required('Degree is required'),
    current_year: Yup.number().required('Current Year is required'),
    graduationYear: Yup.number().required('Graduation Year is required'),
  });

  const formik = useFormik({
    initialValues: {
      department: user.department,
      degree: user.degree,
      current_year: user.current_year,
      graduationYear: user.graduation_year,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSave(values);  // Call the handleSave function on form submit
    },
  });

  return (
    <Box
      className={classes.formContainer}
      sx={{
        display: 'flex',
        alignItems: 'center',
        margin: '1rem',
        width: '95%',
        border: '1px solid #D3D3D3',
        borderRadius: '10px',
        padding: '25px',
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '1rem',
          }}
        >
          <Typography variant="h4" sx={{ color: '#007BFF' }}>
            College Information
          </Typography>
          {!editMode && (
            <Box
              sx={{
                position: 'absolute',
                right: '20px',
                cursor: 'pointer',
              }}
            >
              <Typography
                onClick={handleEdit}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '4px 10px',
                  color: 'rgb(122, 122, 122)',
                  fontSize: '1.1rem',
                  border: '1px solid rgb(122, 122, 122)',
                  borderRadius: '20px',
                }}
              >
                Edit
                <EditIcon
                  sx={{
                    fontSize: '1rem',
                    color: 'rgb(122, 122, 122)',
                    marginLeft: '5px',
                  }}
                />
              </Typography>
            </Box>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography sx={title_styles}>Branch</Typography>
            <FormControl fullWidth>
              <InputLabel id="department-label">Branch</InputLabel>
              <Select
                labelId="department-label"
                id="department"
                name="department"
                value={formik.values.department}
                label="department"
                onChange={formik.handleChange}
                error={formik.touched.department && Boolean(formik.errors.department)}
                disabled={!editMode}
              >
                {department_options.map((option, index) => (
                  <MenuItem key={index} value={option[0]}>
                    {option[1]}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.department && formik.errors.department && (
                <FormHelperText error>{formik.errors.department}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={title_styles}>Current Year</Typography>
            <FormControl fullWidth>
              <InputLabel id="current-year-label">Current Year</InputLabel>
              <Select
                labelId="current-year-label"
                id="current_year"
                name="current_year"
                value={formik.values.current_year}
                label="current_year"
                onChange={formik.handleChange}
                error={formik.touched.current_year && Boolean(formik.errors.current_year)}
                disabled={!editMode}
              >
                {current_year_options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.current_year && formik.errors.current_year && (
                <FormHelperText error>{formik.errors.current_year}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={title_styles}>Degree</Typography>
            <FormControl fullWidth>
              <InputLabel id="degree-label">Degree</InputLabel>
              <Select
                labelId="degree-label"
                id="degree"
                name="degree"
                value={formik.values.degree}
                label="degree"
                placeholder="Enter your expected degree"
                onChange={formik.handleChange}
                error={formik.touched.degree && Boolean(formik.errors.degree)}
                disabled={!editMode}
              >
                {degree_options.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.degree && formik.errors.degree && (
                <FormHelperText error>{formik.errors.degree}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={title_styles}>Graduation Year</Typography>
            <TextField
              label="Graduation Year"
              variant="outlined"
              name="graduationYear"
              value={formik.values.graduationYear}
              onChange={formik.handleChange}
              fullWidth
              disabled={!editMode}
            />
          </Grid>
        </Grid>
        {editMode && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1rem',
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '4px 10px',
                color: 'white',
                fontSize: '1.1rem',
                borderRadius: '20px',
                backgroundColor: 'rgb(0, 123, 255)',
              }}
            >
              Save
            </Button>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default CollegeInfo;
