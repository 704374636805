import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { updateTL } from '../../redux/slices/userSlice'; // Replace with the actual path to your userSlice file
import { Box, Button } from '@mui/material';
import NotificationCard from './notification'; // Replace with the actual path to your NotificationCard component
import TinkerLabListAPIs from '../../utilities/api/TinkersLab/TinkerLabListAPIs';
import CreateTL from './CreateTL'; // Replace with the actual path to your CreateTL component
import {ImageBaseURL} from "../../utilities/api/axios";

// const userAvatarUrl = 'path_to_user_avatar_image'; // Replace with the actual path to your user avatar image

const NavBar = () => {
  const [notifi, setNotifi] = useState(false);
  const [tinkerLabs, setTinkerLabs] = useState([]);
  const [openCreateTL, setOpenCreateTL] = useState(false);
  const { user } = useSelector((state) => state.user.user); // Adjusted access to user state
  const dispatch = useDispatch();
  const navigate = useNavigate();

const userAvatarUrl = user.profile_pic ? `${ImageBaseURL}${user.profile_pic}` : '';

  useEffect(() => {
    const fetchTinkerLabs = async () => {
      try {
        const labs = await TinkerLabListAPIs.TinkerLabsListGet();
        setTinkerLabs(labs);
      } catch (error) {
        console.error('Error fetching Tinker Labs data:', error);
      }
    };

    fetchTinkerLabs();
  }, [openCreateTL]);

  const handleNavigation = () => {
    navigate('/calendar'); // Replace '/calendar' with your desired path
  };

  const handleProfile = () => {
    navigate('/profile'); // Replace '/profile' with your desired path
  };

  const handleTLChange = (event) => {
    const selectedTLName = event.target.value;
    dispatch(updateTL({ TL: selectedTLName }));
  };

  const handleCreateTLOpen = () => {
    setOpenCreateTL(true);
  };

  const handleCreateTLClose = () => {
    setOpenCreateTL(false);
  };

  return (
    <>
      <AppBar position="static" color="inherit" elevation={1}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {user?.is_org_admin ? (
              <Select
                value={user.TL_name || ''}
                onChange={handleTLChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Select TL' }}
              >
                {tinkerLabs.map((lab) => (
                  <MenuItem key={lab.id} value={lab.name}>
                    {lab.name}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              user?.TL_name || ''
            )}
          </Typography>

          {user?.TL_name === 'Org-Admin' && (
            <Button variant="contained" color="primary" onClick={handleCreateTLOpen} sx={{ marginRight: '27px' }}>
              Create TL
            </Button>
          )}

          {/* <IconButton color="inherit" sx={{ marginRight: '27px' }} onClick={() => setNotifi(!notifi)}> */}
            {/* <Badge color="secondary">
              <NotificationAddIcon />
            </Badge> */}
          {/* </IconButton> */}

          <IconButton color="inherit" sx={{ marginRight: '27px' }} onClick={handleNavigation}>
            <CalendarTodayIcon />
          </IconButton>

          <IconButton color="inherit" sx={{ marginRight: '27px' }} onClick={handleProfile}>
            <Avatar alt="User Avatar" src={userAvatarUrl} />
          </IconButton>
        </Toolbar>
      </AppBar>
      {notifi && <NotificationCard />}
      <CreateTL open={openCreateTL} handleClose={handleCreateTLClose} />
    </>
  );
};

export default NavBar;




// import React, { useState, useEffect } from 'react';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import Badge from '@mui/material/Badge';
// import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
// import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import Avatar from '@mui/material/Avatar';
// import { useNavigate } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import { updateCollege } from '../../redux/slices/userSlice'; // Adjust the import path accordingly

// // Replace with your user's avatar image URL
// const userAvatarUrl = 'path_to_user_avatar_image';

// const NavBar = () => {
//   const user = useSelector((state) => state.user.user);

//   console.log('NavBar',user);
  
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const [selectedOption, setSelectedOption] = useState(user.user?.college);

//   useEffect(() => {
//     if (user && user.user.college) {
//       setSelectedOption(user.user.college);
//     }
//   }, [user]);

//   const handleChange = (event) => {
//     const selectedCollege = event.target.value;
//     setSelectedOption(selectedCollege);
//     dispatch(updateCollege({ college: selectedCollege }));
//   };

//   const options = [
//     'ORG Admin',
//     'IIT Bombay',
//     'IIT Gandhinagar',  
//     'IIT Jammu',
//   ];

//   const handleNavigation = () => {
//     navigate('/calendar'); // Replace '/calendar' with your desired path
//   };

//   return (
//     <AppBar position="static" color="inherit" elevation={1}>
//       <Toolbar>
//         <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//           {/* {user && user.user.email === 'dev@mapit.ai' ? ( */}

//           {/* CURRENTLY USING IIT BOMBAY EQUIVALENT TO Org Admin -> WILL HAVE TO CHANGE IT ONCE */}

//           {(user && user.user.college === 'IIT Bombay') || user.user.is_org_admin === true ? (
//             <Select
//               value={selectedOption}
//               onChange={handleChange}
//               variant="outlined"
//               sx={{ minWidth: 300 }}
//             >
//               {options.map((option) => (
//                 <MenuItem key={option} value={option}>
//                   {option}
//                 </MenuItem>
//               ))}
//             </Select>
//           ) : (
//             `Tinkerer's Lab ${user ? user.college : ''}`
//           )}
//         </Typography>

//         <IconButton color="inherit">
//           <Badge color="secondary" sx={{ marginRight: '27px' }}>
//             <NotificationAddIcon />
//           </Badge>
//         </IconButton>

//         <IconButton color="inherit" sx={{ marginRight: '27px' }} onClick={handleNavigation}>
//           <CalendarTodayIcon />
//         </IconButton>

//         <Avatar alt="User Avatar" src={userAvatarUrl} />
//       </Toolbar>
//     </AppBar>
//   );
// };

// export default NavBar;





// import React from 'react';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import Badge from '@mui/material/Badge';
// import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
// import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import Avatar from '@mui/material/Avatar';
// import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';

// // Replace with your user's avatar image URL
// const userAvatarUrl = 'path_to_user_avatar_image';

// const NavBar = () => {

//   const user = useSelector(state => state.user.user);
//   console.log('NavBar',user);

//   const navigate = useNavigate();

//   const handleNavigation = () => {
//     navigate('/calender'); // Replace '/calendar' with your desired path
//   };

//   return (
//     <AppBar position="static" color="inherit" elevation={1}>
//       <Toolbar >
//         <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//           {/* Tinkerer's Lab IIT Gandhinagar */}
//           Tinkerer's Lab {user.user.college}          
//         </Typography>

//         <IconButton color="inherit">
//           <Badge color="secondary" sx={{ marginRight: '27px' }}>
//             <NotificationAddIcon />
//           </Badge>
//         </IconButton>

//         <IconButton color="inherit" sx={{ marginRight: '27px' }} onClick={handleNavigation}>
//           <CalendarTodayIcon />
//         </IconButton>

//         <Avatar alt="User Avatar" src={userAvatarUrl} />
//       </Toolbar>
//     </AppBar>
//   );
// };

// export default NavBar;
