import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AdminReservationList from '../../../utilities/api/reservation/AdminReservationList';
import AdminInventoryIssueAPIs from '../../../utilities/api/inventory/AdminInventoryIssueAPIs';
import AdminInventoryPurchaseAPIs from '../../../utilities/api/inventory/AdminInventoryPurchaseAPIs';
import AdminUserList from '../../../utilities/api/user/AdminUserList';
import ProjectAPIs from '../../../utilities/api/project/AdminProjectAPI';
import MachineListAPIs from '../../../utilities/api/machine/AdminMachineList';

const useFetchData = () => {
  const { user } = useSelector((state) => state.user);

  const [mostUsedMachinesData, setMostUsedMachinesData] = useState(null);
  const [mostIssuedItemsData, setMostIssuedItemsData] = useState(null);
  const [mostPurchasedItemsData, setMostPurchasedItemsData] = useState(null);
  const [weeklyStatisticsData, setWeeklyStatisticsData] = useState({ weeks: [], borrowed: [], reserved: [] });
  const [costVsUseData, setCostVsUseData] = useState([]);
  const [adminRolesData, setAdminRolesData] = useState(null);
  const [keyProjectsData, setKeyProjectsData] = useState(null);
  const [stats, setStats] = useState([]);

  useEffect(() => {
    const fetchMostUsedMachinesData = async () => {
      try {
        const data = await AdminReservationList.ReservationListGet();

        const machineUsage = data.reduce((acc, curr) => {
          if (curr.approved === "Approved" || curr.approved === "AutoApproved") {
            if (!acc[curr.name]) {
              acc[curr.name] = 0;
            }
            acc[curr.name] += curr.duration;
          }
          return acc;
        }, {});

        const labels = Object.keys(machineUsage);
        const usageData = Object.values(machineUsage);

        setMostUsedMachinesData({ labels, data: usageData });
      } catch (error) {
        console.error('Error fetching most used machines data:', error);
      }
    };

    const fetchMostIssuedItemsData = async () => {
      try {
        const data = await AdminInventoryIssueAPIs.InventoryIssueListGet();
        const itemIssueCount = data.reduce((acc, curr) => {
          if (curr.pickup_ === "Yes") {
            if (!acc[curr.name]) {
              acc[curr.name] = 0;
            }
            acc[curr.name] += curr.quantity;
          }
          return acc;
        }, {});

        const labels = Object.keys(itemIssueCount);
        const issueData = Object.values(itemIssueCount);

        setMostIssuedItemsData({ labels, data: issueData });
      } catch (error) {
        console.error('Error fetching most issued items data:', error);
      }
    };

    const fetchMostPurchasedItemsData = async () => {
      try {
        const data = await AdminInventoryPurchaseAPIs.InventoryPurchaseListGet();
        const itemPurchaseCount = data.reduce((acc, curr) => {
          if (curr.pickup_ === "Yes") {
            if (!acc[curr.name]) {
              acc[curr.name] = 0;
            }
            acc[curr.name] += curr.quantity;
          }
          return acc;
        }, {});

        const labels = Object.keys(itemPurchaseCount);
        const purchaseData = Object.values(itemPurchaseCount);

        setMostPurchasedItemsData({ labels, data: purchaseData });
      } catch (error) {
        console.error('Error fetching most purchased items data:', error);
      }
    };

    const fetchWeeklyStatisticsData = async () => {
      try {
        const [inventoryIssues, inventoryPurchases, machineReservations] = await Promise.all([
          AdminInventoryIssueAPIs.InventoryIssueListGet(),
          AdminInventoryPurchaseAPIs.InventoryPurchaseListGet(),
          AdminReservationList.ReservationListGet(),
        ]);

        const processWeeklyData = (data, dateField, filterCondition = () => true) => {
          const weeks = {};
          data.forEach(item => {
            if (filterCondition(item)) {
              const date = new Date(item[dateField]);
              const week = `${date.getFullYear()}-W${Math.ceil((date.getDate() - 1) / 7)}`;
              if (!weeks[week]) {
                weeks[week] = 0;
              }
              weeks[week] += 1;
            }
          });
          return weeks;
        };

        const inventoryBorrowedByWeek = processWeeklyData(inventoryIssues, 'issued_from', (item) => item.pickup_ === "Yes");
        const inventoryPurchasedByWeek = processWeeklyData(inventoryPurchases, 'purchase_datetime', (item) => item.pickup_ === "Yes");
        const machinesReservedByWeek = processWeeklyData(machineReservations, 'start_time', (item) => item.approved === "Approved" || item.approved === "AutoApproved");

        const allWeeks = Array.from(new Set([
          ...Object.keys(inventoryBorrowedByWeek),
          ...Object.keys(inventoryPurchasedByWeek),
          ...Object.keys(machinesReservedByWeek),
        ])).sort();

        const borrowedData = allWeeks.map(week => (inventoryBorrowedByWeek[week] || 0) + (inventoryPurchasedByWeek[week] || 0));
        const reservedData = allWeeks.map(week => machinesReservedByWeek[week] || 0);

        setWeeklyStatisticsData({ weeks: allWeeks, borrowed: borrowedData, reserved: reservedData });
      } catch (error) {
        console.error('Error fetching weekly statistics data:', error);
      }
    };

    const fetchCostVsUseData = async () => {
      try {
        const machineList = await MachineListAPIs.MachineListGet();
        const machineReservations = await AdminReservationList.ReservationListGet();
    
        const machineUsage = machineReservations.reduce((acc, curr) => {
          // Only count reservations with approved status "Approved" or "AutoApproved"
          if (curr.approved === "Approved" || curr.approved === "AutoApproved") {
            if (!acc[curr.machine]) {
              acc[curr.machine] = 0;
            }
            acc[curr.machine] += curr.duration;
          }
          return acc;
        }, {});
    
        const costVsUse = machineList
          .filter(machine => machineUsage[machine.id] > 0) // Filter out machines with zero usage
          .map(machine => ({
            x: machine.purchase_cost || 0,
            y: machineUsage[machine.id] || 0,
            name: machine.name
          }));
    
        setCostVsUseData(costVsUse);
      } catch (error) {
        console.error('Error fetching cost vs use data:', error);
      }
    };
    
    const fetchAdminRolesData = async () => {
      try {
        const data = await AdminUserList.AllUserListGet();
        const roleCount = data.reduce((acc, curr) => {
          if (curr.is_staff) {
            acc['Staff'] = (acc['Staff'] || 0) + 1;
          } else if (curr.is_student) {
            acc['Student'] = (acc['Student'] || 0) + 1;
          } else {
            acc['Other'] = (acc['Other'] || 0) + 1;
          }
          return acc;
        }, {});

        const labels = Object.keys(roleCount);
        const roleData = Object.values(roleCount);

        setAdminRolesData({ labels, data: roleData });
      } catch (error) {
        console.error('Error fetching admin roles data:', error);
      }
    };

    const fetchKeyProjectsData = async () => {
      try {
        const data = await ProjectAPIs.ProjectsGet();
        const projectTypes = {
          '1': 'Academic',
          '2': 'Research',
          '3': 'Personal',
          '4': 'Other',
        };

        const projectTypeCount = data.reduce((acc, curr) => {
          const type = projectTypes[curr.type] || 'Other';
          if (!acc[type]) {
            acc[type] = 0;
          }
          acc[type] += 1;
          return acc;
        }, {});

        const labels = Object.keys(projectTypeCount);
        const projectData = Object.values(projectTypeCount);

        setKeyProjectsData({ labels, data: projectData });
      } catch (error) {
        console.error('Error fetching key projects data:', error);
      }
    };

    const fetchUserStats = async () => {
      try {
        const [userData, machineData, reservationData] = await Promise.all([
          AdminUserList.AllUserListGet(),
          MachineListAPIs.MachineListGet(),
          AdminReservationList.ReservationListGet()
        ]);

        const totalUsers = userData.length;
        const activeUsers = userData.filter(user => {
          const lastLoginDate = new Date(user.last_login);
          const sevenDaysAgo = new Date();
          sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
          return lastLoginDate >= sevenDaysAgo;
        }).length;

        const admins = userData.filter(user => user.is_staff).length;

        const newUsersInLast7Days = userData.filter(user => {
          const createdDate = new Date(user.created);
          const sevenDaysAgo = new Date();
          sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
          return createdDate >= sevenDaysAgo;
        }).length;

        const today = new Date();
        const activeMachines = reservationData.filter(reservation => {
          const startTime = new Date(reservation.start_time);
          return startTime >= today && (reservation.approved === "Approved" || reservation.approved === "AutoApproved");
        }).map(reservation => reservation.machine).filter((value, index, self) => self.indexOf(value) === index).length;

        const totalMachines = machineData.length;

        const statsData = [
          { value: `${((newUsersInLast7Days / totalUsers) * 100).toFixed(0)}%`, description: "increase in total number of users" },
          { value: `${((activeUsers / totalUsers) * 100).toFixed(0)}%`, description: "of the total users are active" },
          { value: `${admins}`, description: "number of admins handling this page" },
          { value: `${newUsersInLast7Days}`, description: "New users in the last 7 days" },
          { value: `${activeUsers}`, description: "Active users in the last 7 days" },
          { value: `${(activeMachines / totalMachines * 100).toFixed(0)}%`, description: "of the total machines are active" }
        ];

        setStats(statsData);
      } catch (error) {
        console.error('Error fetching user stats:', error);
      }
    };

    fetchMostUsedMachinesData();
    fetchMostIssuedItemsData();
    fetchMostPurchasedItemsData();
    fetchWeeklyStatisticsData();
    fetchCostVsUseData();
    fetchAdminRolesData();
    fetchKeyProjectsData();
    fetchUserStats();
  }, [user]);

  return {
    mostUsedMachinesData,
    mostIssuedItemsData,
    mostPurchasedItemsData,
    weeklyStatisticsData,
    costVsUseData,
    adminRolesData,
    keyProjectsData,
    stats,
  };
};

export default useFetchData;



// import { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import AdminReservationList from '../../../utilities/api/reservation/AdminReservationList';
// import AdminInventoryIssueAPIs from '../../../utilities/api/inventory/AdminInventoryIssueAPIs';
// import AdminInventoryPurchaseAPIs from '../../../utilities/api/inventory/AdminInventoryPurchaseAPIs';
// import AdminUserList from '../../../utilities/api/user/AdminUserList';
// import ProjectAPIs from '../../../utilities/api/project/AdminProjectAPI';
// import MachineListAPIs from '../../../utilities/api/machine/AdminMachineList';

// const useFetchData = () => {
//   const { user } = useSelector((state) => state.user);

//   const [mostUsedMachinesData, setMostUsedMachinesData] = useState(null);
//   const [mostIssuedItemsData, setMostIssuedItemsData] = useState(null);
//   const [mostPurchasedItemsData, setMostPurchasedItemsData] = useState(null);
//   const [weeklyStatisticsData, setWeeklyStatisticsData] = useState({ weeks: [], borrowed: [], reserved: [] });
//   const [costVsUseData, setCostVsUseData] = useState([]);
//   const [adminRolesData, setAdminRolesData] = useState(null);
//   const [keyProjectsData, setKeyProjectsData] = useState(null);
//   const [stats, setStats] = useState([]);

//   useEffect(() => {
//     const fetchMostUsedMachinesData = async () => {
//       try {
//         const data = await AdminReservationList.ReservationListGet();
    
//         const machineUsage = data.reduce((acc, curr) => {
//           if (curr.approved === "Approved" || curr.approved === "AutoApproved") {
//             if (!acc[curr.name]) {
//               acc[curr.name] = 0;
//             }
//             acc[curr.name] += curr.duration; // Accumulate the duration of usage
//           }
//           return acc;
//         }, {});
    
//         const labels = Object.keys(machineUsage);
//         const usageData = Object.values(machineUsage);
    
//         setMostUsedMachinesData({ labels, data: usageData });
//       } catch (error) {
//         console.error('Error fetching most used machines data:', error);
//       }
//     };    

//     const fetchMostIssuedItemsData = async () => {
//       try {
//         const data = await AdminInventoryIssueAPIs.InventoryIssueListGet();
//         const itemIssueCount = data.reduce((acc, curr) => {
//           if (!acc[curr.name]) {
//             acc[curr.name] = 0;
//           }
//           acc[curr.name] += curr.quantity;
//           return acc;
//         }, {});

//         const labels = Object.keys(itemIssueCount);
//         const issueData = Object.values(itemIssueCount);

//         setMostIssuedItemsData({ labels, data: issueData });
//       } catch (error) {
//         console.error('Error fetching most issued items data:', error);
//       }
//     };

//     const fetchMostPurchasedItemsData = async () => {
//       try {
//         const data = await AdminInventoryPurchaseAPIs.InventoryPurchaseListGet();
//         const itemPurchaseCount = data.reduce((acc, curr) => {
//           if (!acc[curr.name]) {
//             acc[curr.name] = 0;
//           }
//           acc[curr.name] += curr.quantity;
//           return acc;
//         }, {});

//         const labels = Object.keys(itemPurchaseCount);
//         const purchaseData = Object.values(itemPurchaseCount);

//         setMostPurchasedItemsData({ labels, data: purchaseData });
//       } catch (error) {
//         console.error('Error fetching most purchased items data:', error);
//       }
//     };

//     const fetchWeeklyStatisticsData = async () => {
//       try {
//         const [inventoryIssues, inventoryPurchases, machineReservations] = await Promise.all([
//           AdminInventoryIssueAPIs.InventoryIssueListGet(),
//           AdminInventoryPurchaseAPIs.InventoryPurchaseListGet(),
//           AdminReservationList.ReservationListGet(),
//         ]);

//         const processWeeklyData = (data, dateField) => {
//           const weeks = {};
//           data.forEach(item => {
//             const date = new Date(item[dateField]);
//             const week = `${date.getFullYear()}-W${Math.ceil((date.getDate() - 1) / 7)}`;
//             if (!weeks[week]) {
//               weeks[week] = 0;
//             }
//             weeks[week] += 1;
//           });
//           return weeks;
//         };

//         const inventoryBorrowedByWeek = processWeeklyData(inventoryIssues, 'issued_from');
//         const inventoryPurchasedByWeek = processWeeklyData(inventoryPurchases, 'purchase_datetime');
//         const machinesReservedByWeek = processWeeklyData(machineReservations, 'start_time');

//         const allWeeks = Array.from(new Set([
//           ...Object.keys(inventoryBorrowedByWeek),
//           ...Object.keys(inventoryPurchasedByWeek),
//           ...Object.keys(machinesReservedByWeek),
//         ])).sort();

//         const borrowedData = allWeeks.map(week => (inventoryBorrowedByWeek[week] || 0) + (inventoryPurchasedByWeek[week] || 0));
//         const reservedData = allWeeks.map(week => machinesReservedByWeek[week] || 0);

//         setWeeklyStatisticsData({ weeks: allWeeks, borrowed: borrowedData, reserved: reservedData });
//       } catch (error) {
//         console.error('Error fetching weekly statistics data:', error);
//       }
//     };

//     const fetchCostVsUseData = async () => {
//       try {
//         const machineList = await MachineListAPIs.MachineListGet();
//         const machineReservations = await AdminReservationList.ReservationListGet();

//         const machineUsage = machineReservations.reduce((acc, curr) => {
//           if (!acc[curr.machine]) {
//             acc[curr.machine] = 0;
//           }
//           acc[curr.machine] += curr.duration; // Use duration for usage hours
//           return acc;
//         }, {});

//         const costVsUse = machineList.map(machine => ({
//           x: machine.purchase_cost || 0, // Default to 0 if no cost data is found
//           y: machineUsage[machine.id] || 0, // Default to 0 if no usage data is found
//           name: machine.name
//         }));

//         setCostVsUseData(costVsUse);
//       } catch (error) {
//         console.error('Error fetching cost vs use data:', error);
//       }
//     };

//     const fetchAdminRolesData = async () => {
//       try {
//         const data = await AdminUserList.AllUserListGet();
//         const roleCount = data.reduce((acc, curr) => {
//           if (curr.is_staff) {
//             acc['Staff'] = (acc['Staff'] || 0) + 1;
//           } else if (curr.is_student) {
//             acc['Student'] = (acc['Student'] || 0) + 1;
//           } else {
//             acc['Other'] = (acc['Other'] || 0) + 1;
//           }
//           return acc;
//         }, {});

//         const labels = Object.keys(roleCount);
//         const roleData = Object.values(roleCount);

//         setAdminRolesData({ labels, data: roleData });
//       } catch (error) {
//         console.error('Error fetching admin roles data:', error);
//       }
//     };

//     const fetchKeyProjectsData = async () => {
//       try {
//         const data = await ProjectAPIs.ProjectsGet();
//         const projectTypes = {
//           '1': 'Academic',
//           '2': 'Research',
//           '3': 'Personal',
//           '4': 'Other',
//         };

//         const projectTypeCount = data.reduce((acc, curr) => {
//           const type = projectTypes[curr.type] || 'Other';
//           if (!acc[type]) {
//             acc[type] = 0;
//           }
//           acc[type] += 1;
//           return acc;
//         }, {});

//         const labels = Object.keys(projectTypeCount);
//         const projectData = Object.values(projectTypeCount);

//         setKeyProjectsData({ labels, data: projectData });
//       } catch (error) {
//         console.error('Error fetching key projects data:', error);
//       }
//     };

//     const fetchUserStats = async () => {
//       try {
//         const [userData, machineData, reservationData] = await Promise.all([
//           AdminUserList.AllUserListGet(),
//           MachineListAPIs.MachineListGet(),
//           AdminReservationList.ReservationListGet()
//         ]);

//         const totalUsers = userData.length;
//         const activeUsers = userData.filter(user => {
//           const lastLoginDate = new Date(user.last_login);
//           const sevenDaysAgo = new Date();
//           sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
//           return lastLoginDate >= sevenDaysAgo;
//         }).length;

//         const admins = userData.filter(user => user.is_staff).length;

//         const newUsersInLast7Days = userData.filter(user => {
//           const createdDate = new Date(user.created);
//           const sevenDaysAgo = new Date();
//           sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
//           return createdDate >= sevenDaysAgo;
//         }).length;

//         const today = new Date();
//         const activeMachines = reservationData.filter(reservation => {
//           const startTime = new Date(reservation.start_time);
//           return startTime >= today;
//         }).map(reservation => reservation.machine).filter((value, index, self) => self.indexOf(value) === index).length;

//         const totalMachines = machineData.length;

//         const statsData = [
//           { value: `${((newUsersInLast7Days / totalUsers) * 100).toFixed(0)}%`, description: "increase in total number of users" },
//           { value: `${((activeUsers / totalUsers) * 100).toFixed(0)}%`, description: "of the total users are active" },
//           { value: `${admins}`, description: "number of admins handling this page" },
//           { value: `${newUsersInLast7Days}`, description: "New users in the last 7 days" },
//           { value: `${activeUsers}`, description: "Active users in the last 7 days" },
//           { value: `${(activeMachines / totalMachines * 100).toFixed(0)}%`, description: "of the total machines are active" }
//         ];

//         setStats(statsData);
//       } catch (error) {
//         console.error('Error fetching user stats:', error);
//       }
//     };

//     fetchMostUsedMachinesData();
//     fetchMostIssuedItemsData();
//     fetchMostPurchasedItemsData();
//     fetchWeeklyStatisticsData();
//     fetchCostVsUseData();
//     fetchAdminRolesData();
//     fetchKeyProjectsData();
//     fetchUserStats();
//   }, [user]);

//   return {
//     mostUsedMachinesData,
//     mostIssuedItemsData,
//     mostPurchasedItemsData,
//     weeklyStatisticsData,
//     costVsUseData,
//     adminRolesData,
//     keyProjectsData,
//     stats,
//   };
// };

// export default useFetchData;
