import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { axiosApiService } from '../../../utilities/api/axios';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const MostIssuedItemsChart = ({ tlMappings }) => {
  const chartRef = useRef(null);
  const itemsChartRef = useRef(null);
  const [itemData, setItemData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [equipmentNames, setEquipmentNames] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const itemUsage = {};
        const equipmentSet = new Set();

        const fetchItemData = async (TL_name) => {
          const response = await axiosApiService.get('/inventory_issue_list/', {
            headers: {
              'TL': TL_name,
            },
          });
          return response.data;
        };

        for (const [id, name] of Object.entries(tlMappings)) {
          const data = await fetchItemData(name);
          data.forEach(item => {
            if (!itemUsage[name]) {
              itemUsage[name] = {};
            }
            if (!itemUsage[name][item.name]) {
              itemUsage[name][item.name] = 0;
            }
            itemUsage[name][item.name] += item.quantity;
            equipmentSet.add(item.name);
          });
        }

        const labels = Object.values(tlMappings);
        const equipmentNames = Array.from(equipmentSet);

        const datasets = equipmentNames.map(equipment => ({
          label: equipment,
          data: labels.map(label => itemUsage[label]?.[equipment] || 0),
          backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.6)`,
        }));

        setLabels(labels);
        setItemData(datasets);
        setEquipmentNames(equipmentNames);
      } catch (error) {
        console.error('Error fetching issued items data:', error);
      }
    }

    fetchData();
  }, [tlMappings]);

  useEffect(() => {
    if (itemsChartRef.current) itemsChartRef.current.destroy();

    const hasData = itemData.length > 0 && itemData.some(dataset => dataset.data.some(value => value > 0));

    if (hasData) {
      const maxIssuedItems = Math.ceil(Math.max(...itemData.flatMap(dataset => dataset.data)) * 1.2); // Extend y-axis by 10% and round to the next integer

      const itemsCtx = chartRef.current.getContext('2d');
      itemsChartRef.current = new Chart(itemsCtx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: itemData,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              beginAtZero: true,
              max: maxIssuedItems,
              ticks: {
                stepSize: 1, // Ensure the y-axis values are integers
              },
            },
          },
        },
      });
    }

    return () => {
      if (itemsChartRef.current) itemsChartRef.current.destroy();
    };
  }, [labels, itemData]);

  return (
    <Card sx={{ borderRadius: '24px', backgroundColor: '#f9fafe', borderColor: '#d3d3d3', borderWidth: '1px', borderStyle: 'solid', padding: '16px', width: '90%', height: '336px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CardContent>
        <Typography variant="h6" sx={{ fontSize: '20px', fontWeight: '700', color: '#212121', textAlign: 'center', letterSpacing: 0 }}>Most Issued Items</Typography>
        {itemData.length > 0 && itemData.some(dataset => dataset.data.some(value => value > 0)) ? (
          <Box sx={{ height: '250px', width: '100%' }}>
            <canvas ref={chartRef} style={{ width: '100%' }} />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="250px" width="100%">
            <img src={nodatafoundImage} alt="No Data Found" style={{ width: '200px', marginBottom: '16px' }} />
            <Typography variant='h5' color="textSecondary">
              No data available
            </Typography>
          </Box>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '12px', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ width: '9px', height: '9px', backgroundColor: '#b3acff', borderRadius: '50%' }} />
            <Typography sx={{ marginLeft: '8px', color: '#000', fontSize: '12px' }}>Solder Wire</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ width: '9px', height: '9px', backgroundColor: '#beb8ff', borderRadius: '50%' }} />
            <Typography sx={{ marginLeft: '8px', color: '#000', fontSize: '12px' }}>Multimeter</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ width: '9px', height: '9px', backgroundColor: '#978fed', borderRadius: '50%' }} />
            <Typography sx={{ marginLeft: '8px', color: '#000', fontSize: '12px' }}>Glue Gun</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MostIssuedItemsChart;
