import React from "react";
import NavBar from "../components/Navbar/Navbar";
import { MenuComponent } from "../components/Menu";
import { Box, Typography, IconButton } from "@mui/material";
import ProfileImage from "../components/profile/ProfileImage";
import PersonalInfo from "../components/profile/PersonalInfo";
import CollegeInfo from "../components/profile/CollegeInfo";
import ChangePassword from "../components/profile/ChangePassword";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ProfilePage = ({ collapsed, setCollapsed }) => {

  // SHIFTED THE USER STATE CALL TO THE CHILD COMPONENTS

  // const { access, user } = useSelector((state) => state.user);
  // const userData = user.user;

  // console.log("UserData is ", userData);

  // const [formData, setFormData] = React.useState({
  //   name: userData?.name || "",
  //   username: userData?.username || "",
  //   email: userData?.email || "",
  //   phone: userData?.phone_number || "",
  //   position: userData?.position || "",
  //   studentId: userData?.student_id || "",
  //   id:userData?.id || "",
  //   branch: userData?.department || "",
  //   currentYear: userData?.current_year || "",
  //   graduationYear: userData?.graduation_year || "",
  //   degree: userData?.degree || "",
  //   staff: userData?.is_staff || false,
  //   student: userData?.is_student || false,
  //   imageUrl: userData?.imageUrl || "",
  // });

  return (
    <Box sx={{ display: "flex", height: "auto", overflowX: "hidden" }}>
      <Box sx={{ border: '1px solid blue' }}>
        <MenuComponent collapsed={collapsed} setCollapsed={setCollapsed} />
      </Box>

      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", marginLeft: collapsed ? '7%' : '17.5%', border: '1px solid green' }}>
        <NavBar />
        <Box >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                padding: "1rem",
                color: "#007BFF",
                fontFamily: "Inter",
                fontWeight: "600",
              }}
            >
              My Profile
            </Typography>
          </Box>

          <ProfileImage />
          <PersonalInfo />
          <CollegeInfo />
        </Box>
      </Box>
    </Box>
  );
};

export default ProfilePage;
