import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, MenuItem, Select } from '@mui/material';
import { axiosApiService } from '../../../utilities/api/axios';
import dayjs from 'dayjs';

const UserCard = ({ tlMappings }) => {
  const [userData, setUserData] = useState({
    totalUsers: 0,
    adminCount: 0,
    studentCount: 0,
    percentageChange: 0,
    adminChange: 0,
    studentChange: 0,
  });
  const [timeframe, setTimeframe] = useState('7'); // default to last 7 days

  useEffect(() => {
    async function fetchData() {
      try {
        const tlData = await Promise.all(
          Object.entries(tlMappings).map(async ([tlId, tlName]) => {
            const response = await axiosApiService.get('/auth/users/', {
              headers: {
                'TL': tlName,
              },
            });
            return response.data;
          })
        );

        const combinedData = tlData.flat();

        const totalUsers = combinedData.length;
        const adminCount = combinedData.filter(user => user.is_staff).length;
        const studentCount = combinedData.filter(user => user.is_student).length;

        // Calculate previous period data
        const previousDate = dayjs().subtract(parseInt(timeframe), 'day').toISOString();
        const previousData = combinedData.filter(user => dayjs(user.created).isBefore(previousDate));

        const previousTotalUsers = previousData.length;
        const previousAdminCount = previousData.filter(user => user.is_staff).length;
        const previousStudentCount = previousData.filter(user => user.is_student).length;

        const percentageChange = ((totalUsers - previousTotalUsers) / (previousTotalUsers || 1)) * 100;
        const adminChange = ((adminCount - previousAdminCount) / (previousAdminCount || 1)) * 100;
        const studentChange = ((studentCount - previousStudentCount) / (previousStudentCount || 1)) * 100;

        setUserData({
          totalUsers,
          adminCount,
          studentCount,
          percentageChange: isNaN(percentageChange) ? 0 : percentageChange.toFixed(1),
          adminChange: isNaN(adminChange) ? 0 : adminChange.toFixed(1),
          studentChange: isNaN(studentChange) ? 0 : studentChange.toFixed(1),
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }

    fetchData();
  }, [timeframe, tlMappings]);

  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  return (
    <Card sx={{ padding: '20px', marginBottom: '20px', borderRadius: '24px', backgroundColor: '#f9fafe', borderColor: '#d3d3d3', borderWidth: 1, borderStyle: 'solid' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', flexDirection: 'row' }}>
        <Typography variant="h6" sx={{ fontSize: '20px', color: '#7a7a7a', fontWeight: '600', lineHeight: '18px', letterSpacing: '0' }}>Users</Typography>
        <Select value={timeframe} onChange={handleTimeframeChange} size="small" sx={{ shadowColor: 'rgba(0, 0, 0, 0.04)', shadowOffset: { width: 0, height: 2 }, shadowRadius: 4, elevation: 4, shadowOpacity: 1, padding: '8px', borderColor: '#f2f2f2', backgroundColor: '#fff', borderRadius: '8px', flexDirection: 'row', justifyContent: 'center', borderWidth: 1, borderStyle: 'solid' }}>
          <MenuItem value="7">Last 7 Days</MenuItem>
          <MenuItem value="30">Last 30 Days</MenuItem>
          <MenuItem value="365">Last Year</MenuItem>
        </Select>
      </Box>
      <Typography variant="h4" sx={{ fontSize: '48px', letterSpacing: '-2px', lineHeight: '58px', textAlign: 'center' }}>
        {userData.totalUsers} <span style={{ color: 'green', fontSize: '1rem' }}>+{userData.percentageChange}%</span>
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 2, flexDirection: 'row' }}>
        <Box sx={{ width: '50%', height: '4px', backgroundColor: '#007bff', marginRight: '2px', overflow: 'hidden' }}></Box>
        <Box sx={{ width: '50%', height: '4px', backgroundColor: '#e79a2b', marginLeft: '2px', overflow: 'hidden' }}></Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box sx={{ textAlign: 'center', p: 2, border: 1, borderColor: 'divider', borderRadius: 1, padding: '12px', borderColor: '#f2f2f2', backgroundColor: '#fff', borderRadius: '8px', justifyContent: 'center', borderWidth: 1, borderStyle: 'solid' }}>
            <Typography variant="subtitle2" sx={{ fontSize: '14px', textAlign: 'left' }}>No. of admins</Typography>
            <Typography variant="h6" sx={{ fontSize: '24px', letterSpacing: '-1px', lineHeight: '28px', textAlign: 'left', color: '#242424', fontWeight: '500' }}>
              {userData.adminCount} <span style={{ color: 'green', fontSize: '1rem' }}>+{userData.adminChange}%</span>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ textAlign: 'center', p: 2, border: 1, borderColor: 'divider', borderRadius: 1, padding: '12px', borderColor: '#f2f2f2', backgroundColor: '#fff', borderRadius: '8px', justifyContent: 'center', borderWidth: 1, borderStyle: 'solid' }}>
            <Typography variant="subtitle2" sx={{ fontSize: '14px', textAlign: 'left' }}>No. of students</Typography>
            <Typography variant="h6" sx={{ fontSize: '24px', letterSpacing: '-1px', lineHeight: '28px', textAlign: 'left', color: '#242424', fontWeight: '500' }}>
              {userData.studentCount} <span style={{ color: 'green', fontSize: '1rem' }}>+{userData.studentChange}%</span>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default UserCard;
