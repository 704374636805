import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { MenuComponent } from '../../components/Menu';
import NavBar from '../../components/Navbar/Navbar';
import Projectscont from '../../components/Project/projectscont';

const Project = ({ collapsed, setCollapsed }) => {
  const { user } = useSelector((state) => state.user.user); // Retrieve user from Redux store

  console.log('Dashboard User', user);

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Box sx={{ width: collapsed ? '7%' : '17.5%' }}>
        <MenuComponent collapsed={collapsed} setCollapsed={setCollapsed} />
      </Box>

      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <NavBar />
        <Projectscont />
      </Box>
    </Box>
  );
};

export default Project;
