import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import IconButton from '@mui/material/IconButton';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import nodatafound from '../../utilities/dummy_assets/nodatafound.jpg';
import EquipmentListAPIs from '../../utilities/api/equipment/AdminEquipmentList';
import EquipmentDetailAPIs from '../../utilities/api/equipment/AdminEquipmentDetail';
import Delete from './Popups/Delete';
import EditEquipmentDialog from './Popups/EditEquipment';
import { ImageBaseURL } from "../../utilities/api/axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for toast notifications

const EquipmentsTable = ({ searchQuery, category, update, setUpdate, flag, setFlag }) => {
  const user = useSelector(state => state.user.user);
  const token = user.access;

  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [isEditInventoryOpen, setEditInventoryOpen] = useState(false);

  // const baseURL = 'https://staging.misadmin.mapit.ai/';

  useEffect(() => {
    const response = EquipmentListAPIs.EquipmentListGet();
    response.then((result) => {
      setTableData(result);
    }).catch((error) => {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    });
  }, [update, user]);

  const rows = tableData.map((item, index) => ({ ...item, index: index }));

  const handleEditInventory = (row) => {
    setCurrentRow(row);
    setEditInventoryOpen(true);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleOpenDeletePopup = (row) => {
    setCurrentRow(row);
    setOpenDeletePopup(true);
  };

  const handleCloseDeletePopup = () => {
    setOpenDeletePopup(false);
  };

  const handleDelete = () => {
    const response = EquipmentDetailAPIs.EquipmentDetailDelete(currentRow.id, token);
    response.then(() => {
      setUpdate(prev => !prev);
      toast.success("Item successfully deleted");
    }).catch((error) => {
      console.error("Error deleting data:", error);
      toast.error("Error deleting item");
    });
    handleCloseDeletePopup();
  };

  const columns = [
    {
      field: 'itemDetails',
      headerName: 'Item Details',
      flex: 1,
      headerAlign: 'center',
      headerClassName: 'header-style',
      renderCell: (params) => {
        const imageUrl = params.row && params.row.image ? `${ImageBaseURL}${params.row.image}` : nodatafound;
        const text = params.row && params.row.name ? params.row.name : 'No Text';
        const subtext = params.row && params.row.upc ? params.row.upc : 'No Subtext';

        return (
          <Box sx={{ ml: '10%', display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'center' }}>
            <img src={imageUrl} alt={text} style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
            <Box>
              <Typography variant="subtitle2" sx={{ color: '#434343' }}>{text}</Typography>
              <Typography variant="body2" sx={{ color: '#434343' }}>{subtext}</Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'header-style',
      renderCell: (params) => (
        <Typography sx={{ color: '#434343', textAlign: 'center', width: '100%' }}>
          {params.row.category}
        </Typography>
      )
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'header-style',
      renderCell: (params) => (
        <Typography sx={{ color: '#434343', textAlign: 'center', width: '100%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {params.row.description}
        </Typography>
      )
    },
    {
      field: 'instances',
      headerName: 'Stock',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'header-style',
      renderCell: (params) => (
        <Typography sx={{ color: '#434343', textAlign: 'center', width: '100%' }}>
          {params.row.instances}
        </Typography>
      )
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      width: 150,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'header-style',
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <IconButton aria-label="edit" onClick={() => handleEditInventory(params.row)}>
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => handleOpenDeletePopup(params.row)}>
            <DeleteOutlineSharpIcon fontSize="small" sx={{ color: '#C90505' }} />
          </IconButton>
        </Box>
      ),
    },
  ];

  const filteredRows = rows.filter((row) => {
    const lowercasedCategory = category.map((cat) => cat.toLowerCase());
    const matchesCategory =
      !lowercasedCategory.length || lowercasedCategory.includes(row.category && row.category.toLowerCase());

    const matchesSearchQuery =
      !searchQuery ||
      (row.name && row.name.includes(searchQuery.toLowerCase())) ||
      (row.group && row.group.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (row.description && row.description.toLowerCase().includes(searchQuery.toLowerCase())) ||
      [row.tag_1, row.tag_2, row.tag_3, row.tag_4].some((tag) => tag && tag.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (row.availability && row.availability.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (row.category && row.category.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (row.upc && row.upc.includes(searchQuery.toLowerCase())) ||
      (row.instances && row.instances.toString().includes(searchQuery.toLowerCase()));

    return matchesCategory && matchesSearchQuery;
  });

  return (
    <Box sx={{ height: '60%', width: '97.5%', marginLeft: '1rem' }}>
      {filteredRows.length === 0 ? (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
          <img src={nodatafound} alt="No Data Found" style={{ width: '350px', marginBottom: '8px' }} />
          <Typography variant="body2" color="textSecondary">
            No data available
          </Typography>
        </Box>
      ) : (
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={7}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPageOptions={[3]}
          pagination
          paginationMode="server"
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          disableDensitySelector
          disableExport
          disableMultipleColumnsSorting
          sortingOrder={[]}
          disableColumnResize
          sx={{
            '& .header-style': {
              fontSize: '1.2rem',
              fontWeight: 'bold',
            },
          }}
        />
      )}
      <Delete open={openDeletePopup} onClose={handleCloseDeletePopup} onConfirm={handleDelete} />
      <EditEquipmentDialog
        currentRow={currentRow}
        open={isEditInventoryOpen}
        onClose={() => setEditInventoryOpen(false)}
        update={update}
        setUpdate={setUpdate}
        flag={flag}
        setFlag={setFlag}
      />
      <ToastContainer /> {/* Add ToastContainer here */}
    </Box>
  );
};

export default EquipmentsTable;



// import * as React from 'react';
// import { DataGrid } from '@mui/x-data-grid';
// import { Box, Typography } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
// import IconButton from '@mui/material/IconButton';
// import { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import nodatafound from '../../utilities/dummy_assets/nodatafound.jpg';
// import EquipmentListAPIs from '../../utilities/api/equipment/AdminEquipmentList';
// import EquipmentDetailAPIs from '../../utilities/api/equipment/AdminEquipmentDetail';
// import Delete from './Popups/Delete';
// import EditEquipmentDialog from './Popups/EditEquipment';
// import { ImageBaseURL } from "../../utilities/api/axios";

// const EquipmentsTable = ({ searchQuery, category, update, setUpdate, flag, setFlag }) => {
//   const user = useSelector(state => state.user.user);
//   const token = user.access;

//   const [tableData, setTableData] = useState([]);
//   const [page, setPage] = useState(0);
//   const [openDeletePopup, setOpenDeletePopup] = useState(false);
//   const [currentRow, setCurrentRow] = useState(null);
//   const [isEditInventoryOpen, setEditInventoryOpen] = useState(false);

//   useEffect(() => {
//     const response = EquipmentListAPIs.EquipmentListGet();
//     response.then((result) => {
//       console.log(result);
//       setTableData(result);
//     }).catch((error) => {
//       console.log("error", error);
//     })
//   }, [update, user]);

//   const rows = tableData.map((item, index) => ({ ...item, index: index }));

//   const handleEditInventory = (row) => {
//     setCurrentRow(row);
//     setEditInventoryOpen(true);
//   };

//   const handlePageChange = (newPage) => {
//     setPage(newPage);
//   };

//   const handleOpenDeletePopup = (row) => {
//     setCurrentRow(row);
//     setOpenDeletePopup(true);
//   };

//   const handleCloseDeletePopup = () => {
//     setOpenDeletePopup(false);
//   };

//   const handleDelete = () => {
//     console.log('Delete row:', currentRow);
//     const response = EquipmentDetailAPIs.EquipmentDetailDelete(currentRow.id, token);
//     response.then(() => {
//       setUpdate(prev => !prev);
//     }).catch((error) => {
//       console.log("error while deleting", error);
//     });
//     handleCloseDeletePopup();
//   };

//   const columns = [
//     {
//       field: 'itemDetails',
//       headerName: 'Item Details',
//       flex: 1,
//       headerAlign: 'center',
//       headerClassName: 'header-style',
//       renderCell: (params) => {
//         const imageUrl = params.row && params.row.image ? `${ImageBaseURL}${params.row.image}` : nodatafound;
//         const text = params.row && params.row.name ? params.row.name : 'No Text';
//         const subtext = params.row && params.row.upc ? params.row.upc : 'No Subtext';

//         return (
//           <Box sx={{ ml: '10%', display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'center' }}>
//             <img src={imageUrl} alt={text} style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
//             <Box>
//               <Typography variant="subtitle2" sx={{ color: '#434343' }}>{text}</Typography>
//               <Typography variant="body2" sx={{ color: '#434343' }}>{subtext}</Typography>
//             </Box>
//           </Box>
//         );
//       },
//     },
//     {
//       field: 'category',
//       headerName: 'Category',
//       flex: 1,
//       headerAlign: 'center',
//       align: 'center',
//       headerClassName: 'header-style',
//       renderCell: (params) => (
//         <Typography sx={{ color: '#434343', textAlign: 'center', width: '100%' }}>
//           {params.row.category}
//         </Typography>
//       )
//     },
//     {
//       field: 'description',
//       headerName: 'Description',
//       flex: 1,
//       headerAlign: 'center',
//       align: 'center',
//       headerClassName: 'header-style',
//       renderCell: (params) => (
//         <Typography sx={{ color: '#434343', textAlign: 'center', width: '100%', whiteSpace: 'normal', wordWrap: 'break-word' }}>
//           {params.row.description}
//         </Typography>
//       )
//     },
//     {
//       field: 'instances',
//       headerName: 'Stock',
//       flex: 1,
//       headerAlign: 'center',
//       align: 'center',
//       headerClassName: 'header-style',
//       renderCell: (params) => (
//         <Typography sx={{ color: '#434343', textAlign: 'center', width: '100%' }}>
//           {params.row.instances}
//         </Typography>
//       )
//     },
//     {
//       field: 'action',
//       headerName: 'Action',
//       sortable: false,
//       width: 150,
//       headerAlign: 'center',
//       align: 'center',
//       headerClassName: 'header-style',
//       renderCell: (params) => (
//         <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
//           <IconButton aria-label="edit" onClick={() => handleEditInventory(params.row)}>
//             <EditIcon fontSize="small" />
//           </IconButton>
//           <IconButton aria-label="delete" onClick={() => handleOpenDeletePopup(params.row)}>
//             <DeleteOutlineSharpIcon fontSize="small" sx={{ color: '#C90505' }} />
//           </IconButton>
//         </Box>
//       ),
//     },
//   ];

//   const filteredRows = rows.filter((row) => {
//     const lowercasedCategory = category.map((cat) => cat.toLowerCase());
//     const matchesCategory =
//       !lowercasedCategory.length || lowercasedCategory.includes(row.category && row.category.toLowerCase());

//     const matchesSearchQuery =
//       !searchQuery ||
//       (row.name && row.name.includes(searchQuery.toLowerCase())) ||
//       (row.group && row.group.toLowerCase().includes(searchQuery.toLowerCase())) ||
//       (row.description && row.description.toLowerCase().includes(searchQuery.toLowerCase())) ||
//       [row.tag_1, row.tag_2, row.tag_3, row.tag_4].some((tag) => tag && tag.toLowerCase().includes(searchQuery.toLowerCase())) ||
//       (row.availability && row.availability.toLowerCase().includes(searchQuery.toLowerCase())) ||
//       (row.category && row.category.toLowerCase().includes(searchQuery.toLowerCase())) ||
//       (row.upc && row.upc.includes(searchQuery.toLowerCase())) ||
//       (row.instances && row.instances.toString().includes(searchQuery.toLowerCase()));

//     return matchesCategory && matchesSearchQuery;
//   });

//   return (
//     <Box sx={{ height: '60%', width: '97.5%', marginLeft: '1rem' }}>
//       {filteredRows.length === 0 ? (
//         <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
//           <img src={nodatafound} alt="No Data Found" style={{ width: '350px', marginBottom: '8px' }} />
//           <Typography variant="body2" color="textSecondary">
//             No data available
//           </Typography>
//         </Box>
//       ) : (
//         <DataGrid
//           rows={filteredRows}
//           columns={columns}
//           pageSize={7}
//           page={page}
//           onPageChange={handlePageChange}
//           rowsPerPageOptions={[3]}
//           pagination
//           paginationMode="server"
//           disableSelectionOnClick
//           disableColumnFilter
//           disableColumnMenu
//           disableDensitySelector
//           disableExport
//           disableMultipleColumnsSorting
//           sortingOrder={[]}
//           disableColumnResize
//           sx={{
//             '& .header-style': {
//               fontSize: '1.2rem',
//               fontWeight: 'bold',
//             },
//           }}
//         />
//       )}
//       <Delete open={openDeletePopup} onClose={handleCloseDeletePopup} onConfirm={handleDelete} />
//       <EditEquipmentDialog 
//         currentRow={currentRow} 
//         open={isEditInventoryOpen} 
//         onClose={() => setEditInventoryOpen(false)} 
//         update={update} 
//         setUpdate={setUpdate} 
//         flag={flag} 
//         setFlag={setFlag} 
//       />
//     </Box>
//   );
// };

// export default EquipmentsTable;
