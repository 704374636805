import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit'; // Import Edit icon from Material-UI
import * as Yup from 'yup';
import AdminUserDetail from '../../utilities/api/user/AdminUserDetail';
import { updateUser } from '../../redux/slices/userSlice';
import {ImageBaseURL} from "../../utilities/api/axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProfileImage = () => {
  const { user } = useSelector((state) => state.user.user);
  const dispatch = useDispatch(); // Access the Redux dispatch function
  const [editMode, setEditMode] = React.useState(false);
  const [profilePic, setProfilePic] = React.useState(null);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = async (values) => {
    try {
      let updatedUser;

      if (profilePic) {
        const formData = new FormData();
        formData.append('profile_pic', profilePic);
        formData.append('username', values.username);
        // formData.append('imageUrl', values.imageUrl);

        updatedUser = await AdminUserDetail.AdminDetailUpdate(user.id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        updatedUser = await AdminUserDetail.AdminDetailUpdate(user.id, values);
      }

      console.log('User updated successfully:', updatedUser);
      dispatch(updateUser(updatedUser)); // Dispatch the updateUser action with the updated user data
      setEditMode(false);  // Exit edit mode;

      // Show toast notification
      toast.success('Changes saved! Please log in again for them to reflect.');
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    // imageUrl: Yup.string().url('Invalid URL format').required('Image URL is required'),
  });

  const formik = useFormik({
    initialValues: {
      username: user.username,
      // imageUrl: user.imageUrl,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log('Form submitted with values:', values); // Debug log
      handleSave(values);  // Call the handleSave function on form submit
    },
  });

  const handleProfilePicChange = (event) => {
    setProfilePic(event.currentTarget.files[0]);
  };

  const profilePicUrl = user.profile_pic ? `${ImageBaseURL}/${user.profile_pic}` : '';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        margin: '1rem',
        width: '95%', // Make the component stretch the entire width of the page
        border: '1px solid #D3D3D3', // Add a border around the complete component
        borderRadius: '10px', // Optional: Add border radius for a more polished look
        padding: '25px', // Optional: Add some padding inside the component
        position: 'relative', // Ensure the box is positioned relatively
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <Avatar
          alt="Profile"
          src={profilePic ? URL.createObjectURL(profilePic) : profilePicUrl}
          sx={{ width: 200, height: 200, border: '1px solid #007BFF' }}
        />
        <Box sx={{ marginLeft: '3rem' }}>
          {editMode ? (
            <>
              <TextField
                label="Username"
                variant="outlined"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.username && Boolean(formik.errors.username)}
                helperText={formik.touched.username && formik.errors.username}
                fullWidth
              />
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="profile-pic-upload"
                type="file"
                onChange={handleProfilePicChange}
              />
              <label htmlFor="profile-pic-upload">
                <Button variant="contained" component="span" sx={{ marginTop: '1rem' }}>
                  Upload Profile Picture
                </Button>
              </label>
              {/* <TextField
                label="Image URL"
                variant="outlined"
                name="imageUrl"
                value={formik.values.imageUrl}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.imageUrl && Boolean(formik.errors.imageUrl)}
                helperText={formik.touched.imageUrl && formik.errors.imageUrl}
                fullWidth
                sx={{ marginTop: '1rem' }}
              /> */}
            </>
          ) : (
            <>
              <Typography
                variant="h4"
                sx={{ color: '#007BFF', fontWeight: '500', fontSize: '2.5rem' }}
              >
                {user.username}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 700, opacity: '0.7', fontSize: '1.1rem', color: '#A6A6A6' }}>
                {user.staff ? 'Staff' : 'Admin'}
              </Typography>
              {/* <Typography variant="body2" sx={{ fontWeight: 700, opacity: '0.7', fontSize: '1.1rem', color: '#A6A6A6' }}>
                {user.position}
              </Typography> */}
            </>
          )}
        </Box>
      </Box>
      {editMode ? (
        <Box
          sx={{
            position: 'absolute',
            top: '12px',
            right: '12px', // Adjust these values to position the icon as per your preference
            cursor: 'pointer',
          }}
        >
          <Typography
            onClick={formik.handleSubmit}
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '4px 10px',
              color: '#007BFF',
              fontSize: '1.1rem',
              border: '1px solid #007BFF',
              borderRadius: '20px',
              cursor: 'pointer',
            }}
          >
            Save
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            position: 'absolute',
            top: '12px',
            right: '12px', // Adjust these values to position the icon as per your preference
            cursor: 'pointer',
          }}
          onClick={handleEdit}
        >
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '4px 10px',
              color: '#007BFF',
              fontSize: '1.1rem',
              border: '1px solid #007BFF',
              borderRadius: '20px',
            }}
          >
            Edit
            <EditIcon sx={{ fontSize: '1rem', color: '#007BFF', marginLeft: '5px' }} />
          </Typography>
        </Box>
      )}
      <ToastContainer />
    </Box>
  );
};

export default ProfileImage;
