import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, Typography, Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import InfoIcon from '@mui/icons-material/Info';
import EquipmentListAPIs from '../../../utilities/api/equipment/AdminEquipmentList';
import InventoryCategoryAPIs from '../../../utilities/api/category/AdminInventoryCategoryList';
import InventoryLocationAPIs from '../../../utilities/api/location/AdminLocationList';
import AddEquipmentDialog from './AddEquipment';

const availabilityMapping = {
  'Moderate': 2,
  'Very Low': 0,
  'Low': 2,
  'High': 3,
  'Always': 4
};

const schemaFields = [
  'category', 'location', 'name', 'upc', 'manufacturer', 'description', 
  'status', 'instances', 'purchase_cost', 'purchase_date', 'warranty_expiration'
];

const schemaDescription = [
  "category: The category of the equipment",
  "location: The location of the equipment",
  "name: The name of the equipment",
  "upc: The UPC code",
  "manufacturer: The manufacturer of the equipment",
  "description: A description of the equipment",
  "status: The operational status",
  "instances: Number of instances",
  "purchase_cost: The purchase cost",
  "purchase_date: The purchase date",
  "warranty_expiration: The warranty expiration date",
];

const ImportEquipment = ({ token, isOpen, onClose }) => {
  const [csvData, setCsvData] = useState(null);
  const [mapping, setMapping] = useState({});
  const [mappingDialogOpen, setMappingDialogOpen] = useState(false);
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [errorEntries, setErrorEntries] = useState([]);
  const [successfulImports, setSuccessfulImports] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [newCategories, setNewCategories] = useState([]);
  const [newLocations, setNewLocations] = useState([]);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchCategoriesAndLocations = async () => {
      try {
        const [categoriesResponse, locationsResponse] = await Promise.all([
          InventoryCategoryAPIs.CategoryListGet(),
          InventoryLocationAPIs.LocationListGet()
        ]);
        setCategories(categoriesResponse.map(category => category.name));
        setLocations(locationsResponse.map(location => location.name));
      } catch (error) {
        console.error('Error fetching categories or locations:', error);
      }
    };

    fetchCategoriesAndLocations();
  }, []);

  const handleFileLoaded = (data) => {
    const headers = data[0];
    const rows = data.slice(1);
    const initialMapping = {};

    schemaFields.forEach(field => {
      initialMapping[field] = headers.includes(field) ? field : '';
    });

    setCsvData({ headers, rows });
    setMapping(initialMapping);
    setTotalEntries(rows.length); // Set total entries
    setMappingDialogOpen(true);
    onClose();
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      const csvData = reader.result;
      handleFileLoaded(csvData.split('\n').map(row => row.split(',')));
    };
    reader.readAsText(file);
  };

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileChange(file);
    }
  }, []);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const updateInventory = async (mapping) => {
    if (!csvData || Object.keys(mapping).length === 0) return;
  
    const { headers, rows } = csvData;
    const errors = [];
    let successfulImports = 0;
    let newCategories = [];
    let newLocations = [];
  
    for (let row of rows) {
      const entry = {};
  
      for (let i = 0; i < headers.length; i++) {
        const csvField = headers[i];
        const schemaField = Object.keys(mapping).find(key => mapping[key] === csvField);
        let value = row[i];
  
        if (schemaField && schemaField === 'availability' && availabilityMapping[value] !== undefined) {
          value = availabilityMapping[value];
        }
  
        if (schemaField) {
          entry[schemaField] = value;
        }
      }
  
      // Handle Category
      if (!categories.includes(entry.category)) {
        try {
          await InventoryCategoryAPIs.CategoryListPost(entry.category, token);
          setCategories((prevCategories) => [...prevCategories, entry.category]);
          newCategories.push(entry.category);
        } catch (error) {
          if (error.response?.status === 400 || error.response?.status === 409) {
            // Ignore error if category already exists
            console.log(`Category '${entry.category}' already exists, ignoring error.`);
          } else {
            errors.push({
              entry,
              error: `Failed to add category: ${entry.category}. ${error.message}`,
            });
            continue; // Skip to the next entry if a different error occurs
          }
        }
      }
  
      // Handle Location
      if (!locations.includes(entry.location)) {
        try {
          await InventoryLocationAPIs.LocationListPost(entry.location, token);
          setLocations((prevLocations) => [...prevLocations, entry.location]);
          newLocations.push(entry.location);
        } catch (error) {
          if (error.response?.status === 400 || error.response?.status === 409) {
            // Ignore error if location already exists
            console.log(`Location '${entry.location}' already exists, ignoring error.`);
          } else {
            errors.push({
              entry,
              error: `Failed to add location: ${entry.location}. ${error.message}`,
            });
            continue; // Skip to the next entry if a different error occurs
          }
        }
      }
  
      // Add Inventory
      try {
        await EquipmentListAPIs.AddInventory(entry, token);
        successfulImports++;
      } catch (error) {
        errors.push({ entry, error: error.response?.data || error.message });
      }
    }
  
    setErrorEntries(errors);
    setMappingDialogOpen(false);
    setResultDialogOpen(true);
    setSuccessfulImports(successfulImports);
    setNewCategories(newCategories);
    setNewLocations(newLocations);
  };   

  const handleClose = () => {
    onClose();
    setMappingDialogOpen(false);
    setResultDialogOpen(false);
  };

  const handleInfoOpen = () => {
    setInfoDialogOpen(true);
  };

  const handleInfoClose = () => {
    setInfoDialogOpen(false);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth sx={{ '& .MuiDialog-paper': { borderRadius: '24px' } }}>
        <DialogContent>
          <IconButton
            aria-label="info"
            onClick={handleInfoOpen}
            sx={{
              position: 'absolute',
              right: 20,
              top: 16,
              color: (theme) => theme.palette.grey[500],
              zIndex:'1000'
            }}
          >
            <InfoIcon />
          </IconButton>
          <div onDrop={handleDrop} onDragOver={handleDragOver}>
            <input
              type="file"
              accept=".csv"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={(event) => {
                const file = event.target.files[0];
                if (file) {
                  handleFileChange(file);
                }
              }}
            />
            {isOpen && (
              <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '50vh', cursor: 'pointer', border: '2px dashed #ccc', borderRadius: '12px', padding: '20px', textAlign: 'center' }} onClick={handleIconClick}>
                <CreateNewFolderIcon sx={{ fontSize: 80, color: '#ccc' }} />
                <Typography variant="h6" sx={{ mt: 2 }}>Click to select a file to import</Typography>
                <Typography variant="body2">Or drag and drop it here</Typography>
              </Box>
            )}
          </div>
        </DialogContent>
      </Dialog>
      
      <MappingDialog
        open={mappingDialogOpen}
        headers={csvData ? csvData.headers : []}
        schemaFields={schemaFields}
        mapping={mapping}
        onClose={() => setMappingDialogOpen(false)}
        onSave={(newMapping) => {
          setMapping(newMapping);
          updateInventory(newMapping);
        }}
        onInfoOpen={handleInfoOpen}
      />

      <ResultDialog
        open={resultDialogOpen}
        errors={errorEntries}
        onClose={handleClose}
        successfulImports={successfulImports}
        totalEntries={totalEntries}
        newCategories={newCategories}
        newLocations={newLocations}
        onInfoOpen={handleInfoOpen}
      />

      <InfoDialog
        open={infoDialogOpen}
        onClose={handleInfoClose}
        schemaDescription={schemaDescription}
      />
    </>
  );
};

// MappingDialog Component for field mapping UI
const MappingDialog = ({ open, headers, schemaFields, mapping, onClose, onSave, onInfoOpen }) => {
  const [localMapping, setLocalMapping] = useState(mapping);
  const [usedFields, setUsedFields] = useState([]);

  useEffect(() => {
    setLocalMapping(mapping);
    setUsedFields(Object.values(mapping).filter(field => field !== ''));
  }, [mapping]);

  const handleMappingChange = (schemaField, csvField) => {
    const newMapping = { ...localMapping, [schemaField]: csvField };
    setLocalMapping(newMapping);
    setUsedFields(Object.values(newMapping).filter(field => field !== ''));
  };

  const handleSave = () => {
    onSave(localMapping);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{"& .MuiDialog-paper": { borderRadius: "24px", background:'#f1f6fd', } }}
    >
      <DialogTitle sx={{fontSize:'32px'}}>Column Mapping
        <IconButton
          aria-label="info"
          onClick={onInfoOpen}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <InfoIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Select the MIS field to import, then set how you would like these
          converted to fields in CSV.
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#F2F2F2" }}>
                <TableCell>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ color: "#5c5c5c" }}
                  >
                    MIS Field
                  </Typography>
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ color: "#5c5c5c" }}
                  >
                    CSV Field
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schemaFields.map((field) => (
                <TableRow key={field} sx={{ backgroundColor: "#FFFFFF" }}>
                  <TableCell>
                    <Typography variant="body1" sx={{color:"#898989"}}>{field}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1" >→</Typography>
                  </TableCell>
                  <TableCell>
                    <Select
                      fullWidth
                      value={localMapping[field] || ""}
                      onChange={(e) =>
                        handleMappingChange(field, e.target.value)
                      }
                      sx={{color:"#898989"}}
                    >
                      <MenuItem value="">
                        <em>Don't map this field</em>
                      </MenuItem>
                      {headers.map((header) => (
                        <MenuItem
                          key={header}
                          value={header}
                          disabled={usedFields.includes(header)}
                        >
                          {header}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} sx={{background:'#007BFF', color:'#FFFFFF', borderRadius:'12px', padding:'12px 16px'}}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// ResultDialog Component to show successful or failed entries 
const ResultDialog = ({ open, errors, onClose, successfulImports, totalEntries, newCategories, newLocations, onInfoOpen }) => {
  const [showAddEquipment, setShowAddEquipment] = useState(false);

  const handleAddEquipmentClick = () => {
    setShowAddEquipment(true);
  };

  const handleCloseAddEquipment = () => {
    setShowAddEquipment(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" minWidth="md" sx={{ '& .MuiDialog-paper': { borderRadius: '24px', background:'#f1f6fd' } }}>
      <DialogTitle>
        {showAddEquipment ? 'Add Equipment' : (errors.length > 0 ? 'Failed Entries' : 'Success')}
        <IconButton
          aria-label="info"
          onClick={onInfoOpen}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <InfoIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {showAddEquipment ? (
          <AddEquipmentDialog open={showAddEquipment} onClose={handleCloseAddEquipment} />
        ) : (
          <>
            {errors.length > 0 ? (
              <>
                <Typography variant="h6" sx={{ mb: 2 }}>You can resolve it!</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Typography variant="outlined">Correct the CSV File</Typography>
                  <Typography variant="body1" sx={{ mx: 2 }}>or</Typography>
                  <Button variant="outlined" sx={{background:'#007BFF', color:'#FFFFFF', borderRadius:'12px', padding:'8px 12px'}} onClick={handleAddEquipmentClick}>Manually add Equipment</Button>
                </Box>
                <Typography variant="body1" sx={{ mb: 2 , textAlign:'center', fontSize:'larger', border:'1px solid', borderRadius:'32px'}}>
                  Successfully imported entries: {successfulImports} /{" "}
                  {totalEntries}
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow sx={{ backgroundColor: '#9E9E9E' }}>
                        <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">Name</Typography></TableCell>
                        <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">UPC</Typography></TableCell>
                        <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">Description</Typography></TableCell>
                        <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">Instances</Typography></TableCell>
                        <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">Category</Typography></TableCell>
                        <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">Location</Typography></TableCell>
                        <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">Error</Typography></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {errors.map((error, index) => (
                        <TableRow key={index} sx={{ backgroundColor: '#FFFFFF' }}>
                          <TableCell><Typography variant="body1" sx={{color:"#898989", textAlign:'center'}}>{error.entry.name}</Typography></TableCell>
                          <TableCell><Typography variant="body1" sx={{color:"#898989", textAlign:'center'}}>{error.entry.upc}</Typography></TableCell>
                          <TableCell><Typography variant="body1" sx={{color:"#898989", textAlign:'center'}}>{error.entry.description}</Typography></TableCell>
                          <TableCell><Typography variant="body1" sx={{color:"#898989", textAlign:'center'}}>{error.entry.instances}</Typography></TableCell>
                          <TableCell><Typography variant="body1" sx={{color:"#898989", textAlign:'center'}}>{error.entry.category}</Typography></TableCell>
                          <TableCell><Typography variant="body1" sx={{color:"#898989", textAlign:'center'}}>{error.entry.location}</Typography></TableCell>
                          <TableCell>
                            <Typography variant="body1" sx={{ color: 'red' }}>
                              {typeof error.error === 'object' ? JSON.stringify(error.error) : error.error}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {(newCategories.length > 0 || newLocations.length > 0) && (
                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    {newCategories.length > 0 && (
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6">New Categories Added:</Typography>
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                          <Table>
                            <TableHead>
                              <TableRow sx={{ backgroundColor: '#9E9E9E' }}>
                                <TableCell sx={{ color: '#FFFFFF' }}>
                                  <Typography variant="body1" fontWeight="bold">Category Name</Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {newCategories.map((category, index) => (
                                <TableRow key={index} sx={{ backgroundColor: '#F2F2F2' }}>
                                  <TableCell><Typography variant="body1">{category}</Typography></TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )}
                    {newLocations.length > 0 && (
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6">New Locations Added:</Typography>
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                          <Table>
                            <TableHead>
                              <TableRow sx={{ backgroundColor: '#9E9E9E' }}>
                                <TableCell sx={{ color: '#FFFFFF' }}>
                                  <Typography variant="body1" fontWeight="bold">Location Name</Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {newLocations.map((location, index) => (
                                <TableRow key={index} sx={{ backgroundColor: '#F2F2F2' }}>
                                  <TableCell><Typography variant="body1">{location}</Typography></TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )}
                  </Grid>
                )}
              </>
            ) : (
              <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Typography variant="h4" sx={{ color: 'green', mb: 2 }}>All entries have been successfully added.</Typography>
                <Typography variant="body1">Successfully imported entries: {successfulImports} / {totalEntries}</Typography>
              </Box>
            )}
          </>
        )}
      </DialogContent>
      {!showAddEquipment && (
        <DialogActions>
          <Button onClick={onClose} sx={{ background: '#007BFF', color: '#FFFFFF', borderRadius: '12px', padding: '12px 8px' }}>Close</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

const InfoDialog = ({ open, onClose, schemaDescription }) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth="sm"
    fullWidth
    sx={{ "& .MuiDialog-paper": { borderRadius: "24px", padding: "20px" } }}
  >
    <DialogTitle sx={{ fontSize: "24px", fontWeight: "bold" }}>Schema Information</DialogTitle>
    <DialogContent>
      <Typography variant="body1" sx={{ mb: 2 }}>
        These are the mandatory fields:
      </Typography>
      <Box component="ul" sx={{ listStyleType: "none", padding: 0, mb: 2, pl: 2 }}>
        <li>
          <Typography variant="body2" sx={{ mb: 1, paddingBottom: "8px" }}>
            <strong>name:</strong> Product type is required
          </Typography>
        </li>
        <li>
          <Typography variant="body2" sx={{ mb: 1, paddingBottom: "8px" }}>
            <strong>upc:</strong> UPC is required
          </Typography>
        </li>
        <li>
          <Typography variant="body2" sx={{ mb: 1, paddingBottom: "8px" }}>
            <strong>instances:</strong> Instances is required
          </Typography>
        </li>
        <li>
          <Typography variant="body2" sx={{ mb: 1, paddingBottom: "8px" }}>
            <strong>description:</strong> Description is required
          </Typography>
        </li>
      </Box>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Available fields:
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#9E9E9E" }}>
              <TableCell>
                <Typography variant="body1" fontWeight="bold" sx={{ color: "#FFFFFF" }}>
                  Field
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" fontWeight="bold" sx={{ color: "#FFFFFF" }}>
                  Description
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" fontWeight="bold" sx={{ color: "#FFFFFF" }}>
                  Options
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schemaDescription.map((desc, index) => {
              const [field, description] = desc.split(": ");
              let options = "";
              if (field === "availability") {
                options = "Moderate, Very Low, Low, High, Always";
              } else if (field === "status") {
                options = "Operational, Under Maintenance, Out of Service";
              }

              return (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="body2" sx={{ color: "#898989" }}>
                      {field}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" sx={{ color: "#898989" }}>
                      {description}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" sx={{ color: "#898989" }}>
                      {options}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} sx={{ background: '#007BFF', color: '#FFFFFF', borderRadius: '12px', padding: '12px 8px' }}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default ImportEquipment;



// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, Typography, Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material';
// import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
// import CloseIcon from '@mui/icons-material/Close';
// import EquipmentListAPIs from '../../../utilities/api/equipment/AdminEquipmentList';
// import InventoryCategoryAPIs from '../../../utilities/api/category/AdminInventoryCategoryList';
// import InventoryLocationAPIs from '../../../utilities/api/location/AdminLocationList';
// import AddEquipmentDialog from './AddEquipment';

// const availabilityMapping = {
//   'Moderate': 2,
//   'Very Low': 0,
//   'Low': 2,
//   'High': 3,
//   'Always': 4
// };

// const schemaFields = [
//   'category', 'location', 'name', 'upc', 'manufacturer', 'description', 
//   'status', 'instances', 'purchase_cost', 'purchase_date', 'warranty_expiration', 'college'
// ];

// const ImportEquipment = ({ token, isOpen, onClose }) => {
//   const [csvData, setCsvData] = useState(null);
//   const [mapping, setMapping] = useState({});
//   const [mappingDialogOpen, setMappingDialogOpen] = useState(false);
//   const [resultDialogOpen, setResultDialogOpen] = useState(false);
//   const [errorEntries, setErrorEntries] = useState([]);
//   const [successfulImports, setSuccessfulImports] = useState(0);
//   const [totalEntries, setTotalEntries] = useState(0);
//   const [categories, setCategories] = useState([]);
//   const [locations, setLocations] = useState([]);
//   const [newCategories, setNewCategories] = useState([]);
//   const [newLocations, setNewLocations] = useState([]);
//   const fileInputRef = useRef(null);

//   useEffect(() => {
//     const fetchCategoriesAndLocations = async () => {
//       try {
//         const [categoriesResponse, locationsResponse] = await Promise.all([
//           InventoryCategoryAPIs.CategoryListGet(),
//           InventoryLocationAPIs.LocationListGet()
//         ]);
//         setCategories(categoriesResponse.map(category => category.name));
//         setLocations(locationsResponse.map(location => location.name));
//       } catch (error) {
//         console.error('Error fetching categories or locations:', error);
//       }
//     };

//     fetchCategoriesAndLocations();
//   }, []);

//   const handleFileLoaded = (data) => {
//     const headers = data[0];
//     const rows = data.slice(1);
//     const initialMapping = {};

//     schemaFields.forEach(field => {
//       initialMapping[field] = headers.includes(field) ? field : '';
//     });

//     setCsvData({ headers, rows });
//     setMapping(initialMapping);
//     setTotalEntries(rows.length); // Set total entries
//     setMappingDialogOpen(true);
//     onClose();
//   };

//   const handleIconClick = () => {
//     fileInputRef.current.click();
//   };

//   const handleFileChange = (file) => {
//     const reader = new FileReader();
//     reader.onload = () => {
//       const csvData = reader.result;
//       handleFileLoaded(csvData.split('\n').map(row => row.split(',')));
//     };
//     reader.readAsText(file);
//   };

//   const handleDrop = useCallback((event) => {
//     event.preventDefault();
//     const file = event.dataTransfer.files[0];
//     if (file) {
//       handleFileChange(file);
//     }
//   }, []);

//   const handleDragOver = (event) => {
//     event.preventDefault();
//   };

//   const updateInventory = async (mapping) => {
//     if (!csvData || Object.keys(mapping).length === 0) return;

//     const { headers, rows } = csvData;
//     const errors = [];
//     let successfulImports = 0;
//     let newCategories = [];
//     let newLocations = [];

//     for (let row of rows) {
//       const entry = {};

//       for (let i = 0; i < headers.length; i++) {
//         const csvField = headers[i];
//         const schemaField = Object.keys(mapping).find(key => mapping[key] === csvField);
//         let value = row[i];

//         if (schemaField && schemaField === 'availability' && availabilityMapping[value] !== undefined) {
//           value = availabilityMapping[value];
//         }

//         if (schemaField) {
//           entry[schemaField] = value;
//         }
//       }

//       if (!categories.includes(entry.category)) {
//         try {
//           const newCategory = await InventoryCategoryAPIs.CategoryListPost(
//             entry.category,
//             token
//           );
//           setCategories((prevCategories) => [
//             ...prevCategories,
//             entry.category,
//           ]);
//           newCategories.push(entry.category);
//         } catch (error) {
//           errors.push({
//             entry,
//             error: `Failed to add category: ${entry.category}. ${error.message}`,
//           });
//           continue;
//         }
//       }

//       if (!locations.includes(entry.location)) {
//         try {
//           const newLocation = await InventoryLocationAPIs.LocationListPost(
//             entry.location,
//             token
//           );
//           setLocations((prevLocations) => [...prevLocations, entry.location]);
//           newLocations.push(entry.location);
//         } catch (error) {
//           errors.push({
//             entry,
//             error: `Failed to add location: ${entry.location}. ${error.message}`,
//           });
//           continue;
//         }
//       }

//       try {
//         await EquipmentListAPIs.AddInventory(entry, token);
//         successfulImports++;
//       } catch (error) {
//         errors.push({ entry, error: error.response?.data || error.message });
//       }
//     }

//     setErrorEntries(errors);
//     setMappingDialogOpen(false);
//     setResultDialogOpen(true);
//     setSuccessfulImports(successfulImports);
//     setNewCategories(newCategories);
//     setNewLocations(newLocations);
//   };

//   const handleClose = () => {
//     onClose();
//     setMappingDialogOpen(false);
//     setResultDialogOpen(false);
//   };

//   return (
//     <>
//       <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth sx={{ '& .MuiDialog-paper': { borderRadius: '24px' } }}>
//         <DialogContent>
//           <div onDrop={handleDrop} onDragOver={handleDragOver}>
//             <input
//               type="file"
//               accept=".csv"
//               ref={fileInputRef}
//               style={{ display: 'none' }}
//               onChange={(event) => {
//                 const file = event.target.files[0];
//                 if (file) {
//                   handleFileChange(file);
//                 }
//               }}
//             />
//             {isOpen && (
//               <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '50vh', cursor: 'pointer', border: '2px dashed #ccc', borderRadius: '12px', padding: '20px', textAlign: 'center' }} onClick={handleIconClick}>
//                 {/* <IconButton
//                   aria-label="close"
//                   onClick={handleClose}
//                   sx={{
//                     position: 'absolute',
//                     right: 8,
//                     top: 8,
//                     color: (theme) => theme.palette.grey[500],
//                   }}
//                 >
//                   <CloseIcon />
//                 </IconButton> */}
//                 <CreateNewFolderIcon sx={{ fontSize: 80, color: '#ccc' }} />
//                 <Typography variant="h6" sx={{ mt: 2 }}>Click to select a file to import</Typography>
//                 <Typography variant="body2">Or drag and drop it here</Typography>
//               </Box>
//             )}
//           </div>
//         </DialogContent>
//       </Dialog>
      
//       <MappingDialog
//         open={mappingDialogOpen}
//         headers={csvData ? csvData.headers : []}
//         schemaFields={schemaFields}
//         mapping={mapping}
//         onClose={() => setMappingDialogOpen(false)}
//         onSave={(newMapping) => {
//           setMapping(newMapping);
//           updateInventory(newMapping);
//         }}
//       />

//       <ResultDialog
//         open={resultDialogOpen}
//         errors={errorEntries}
//         onClose={handleClose}
//         successfulImports={successfulImports}
//         totalEntries={totalEntries}
//         newCategories={newCategories}
//         newLocations={newLocations}
//       />
//     </>
//   );
// };

// // MappingDialog Component for field mapping UI
// const MappingDialog = ({ open, headers, schemaFields, mapping, onClose, onSave }) => {
//   const [localMapping, setLocalMapping] = useState(mapping);
//   const [usedFields, setUsedFields] = useState([]);

//   useEffect(() => {
//     setLocalMapping(mapping);
//     setUsedFields(Object.values(mapping).filter(field => field !== ''));
//   }, [mapping]);

//   const handleMappingChange = (schemaField, csvField) => {
//     const newMapping = { ...localMapping, [schemaField]: csvField };
//     setLocalMapping(newMapping);
//     setUsedFields(Object.values(newMapping).filter(field => field !== ''));
//   };

//   const handleSave = () => {
//     onSave(localMapping);
//     onClose();
//   };

//   return (
//     <Dialog
//       open={open}
//       onClose={onClose}
//       maxWidth="md"
//       fullWidth
//       sx={{"& .MuiDialog-paper": { borderRadius: "24px", background:'#f1f6fd', } }}
//     >
//       <DialogTitle sx={{fontSize:'32px'}}>Column Mapping</DialogTitle>
//       <DialogContent>
//         <Typography variant="body2" sx={{ mb: 2 }}>
//           Select the MIS field to import, then set how you would like these
//           converted to fields in CSV.
//         </Typography>
//         <TableContainer component={Paper}>
//           <Table>
//             <TableHead>
//               <TableRow sx={{ backgroundColor: "#F2F2F2" }}>
//                 <TableCell>
//                   <Typography
//                     variant="body1"
//                     fontWeight="bold"
//                     sx={{ color: "#5c5c5c" }}
//                   >
//                     MIS Field
//                   </Typography>
//                 </TableCell>
//                 <TableCell align="center"></TableCell>
//                 <TableCell>
//                   <Typography
//                     variant="body1"
//                     fontWeight="bold"
//                     sx={{ color: "#5c5c5c" }}
//                   >
//                     CSV Field
//                   </Typography>
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {schemaFields.map((field) => (
//                 <TableRow key={field} sx={{ backgroundColor: "#FFFFFF" }}>
//                   <TableCell>
//                     <Typography variant="body1" sx={{color:"#898989"}}>{field}</Typography>
//                   </TableCell>
//                   <TableCell align="center">
//                     <Typography variant="body1" >→</Typography>
//                   </TableCell>
//                   <TableCell>
//                     <Select
//                       fullWidth
//                       value={localMapping[field] || ""}
//                       onChange={(e) =>
//                         handleMappingChange(field, e.target.value)
//                       }
//                       sx={{color:"#898989"}}
//                     >
//                       <MenuItem value="">
//                         <em>Don't map this field</em>
//                       </MenuItem>
//                       {headers.map((header) => (
//                         <MenuItem
//                           key={header}
//                           value={header}
//                           disabled={usedFields.includes(header)}
//                         >
//                           {header}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </DialogContent>
//       <DialogActions>
//         {/* <Button onClick={onClose} color="primary">
//           Cancel
//         </Button> */}
//         <Button onClick={handleSave} sx={{background:'#007BFF', color:'#FFFFFF', borderRadius:'12px', padding:'12px 16px'}}>
//           Done
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// // ResultDialog Component to show successful or failed entries 
// const ResultDialog = ({ open, errors, onClose, successfulImports, totalEntries, newCategories, newLocations }) => {
//   const [showAddEquipment, setShowAddEquipment] = useState(false);

//   const handleAddEquipmentClick = () => {
//     setShowAddEquipment(true);
//   };

//   const handleCloseAddEquipment = () => {
//     setShowAddEquipment(false);
//   };

//   return (
//     <Dialog open={open} onClose={onClose} maxWidth="lg" minWidth="md" sx={{ '& .MuiDialog-paper': { borderRadius: '24px', background:'#f1f6fd' } }}>
//       <DialogTitle>{showAddEquipment ? 'Add Equipment' : (errors.length > 0 ? 'Failed Entries' : 'Success')}</DialogTitle>
//       <DialogContent>
//         {showAddEquipment ? (
//           <AddEquipmentDialog open={showAddEquipment} onClose={handleCloseAddEquipment} />
//         ) : (
//           <>
//             {errors.length > 0 ? (
//               <>
//                 <Typography variant="h6" sx={{ mb: 2 }}>You can resolve it!</Typography>
//                 <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
//                   {/* <Button variant="outlined">Correct the CSV File</Button> */}
//                   <Typography variant="outlined">Correct the CSV File</Typography>
//                   <Typography variant="body1" sx={{ mx: 2 }}>or</Typography>
//                   <Button variant="outlined" sx={{background:'#007BFF', color:'#FFFFFF', borderRadius:'12px', padding:'8px 12px'}} onClick={handleAddEquipmentClick}>Manually add Equipment</Button>
//                 </Box>
//                 <Typography variant="body1" sx={{ mb: 2 , textAlign:'center', fontSize:'larger', border:'1px solid', borderRadius:'32px'}}>
//                   Successfully imported entries: {successfulImports} /{" "}
//                   {totalEntries}
//                 </Typography>
//                 <TableContainer component={Paper}>
//                   <Table>
//                     <TableHead>
//                       <TableRow sx={{ backgroundColor: '#9E9E9E' }}>
//                         <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">Name</Typography></TableCell>
//                         <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">UPC</Typography></TableCell>
//                         <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">Description</Typography></TableCell>
//                         <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">Instances</Typography></TableCell>
//                         <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">Category</Typography></TableCell>
//                         <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">Location</Typography></TableCell>
//                         <TableCell sx={{ color: '#FFFFFF' }}><Typography variant="body1" fontWeight="bold">Error</Typography></TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {errors.map((error, index) => (
//                         <TableRow key={index} sx={{ backgroundColor: '#FFFFFF' }}>
//                           <TableCell><Typography variant="body1" sx={{color:"#898989", textAlign:'center'}}>{error.entry.name}</Typography></TableCell>
//                           <TableCell><Typography variant="body1" sx={{color:"#898989", textAlign:'center'}}>{error.entry.upc}</Typography></TableCell>
//                           <TableCell><Typography variant="body1" sx={{color:"#898989", textAlign:'center'}}>{error.entry.description}</Typography></TableCell>
//                           <TableCell><Typography variant="body1" sx={{color:"#898989", textAlign:'center'}}>{error.entry.instances}</Typography></TableCell>
//                           <TableCell><Typography variant="body1" sx={{color:"#898989", textAlign:'center'}}>{error.entry.category}</Typography></TableCell>
//                           <TableCell><Typography variant="body1" sx={{color:"#898989", textAlign:'center'}}>{error.entry.location}</Typography></TableCell>
//                           <TableCell>
//                             <Typography variant="body1" sx={{ color: 'red' }}>
//                               {typeof error.error === 'object' ? JSON.stringify(error.error) : error.error}
//                             </Typography>
//                           </TableCell>
//                         </TableRow>
//                       ))}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>
//                 {(newCategories.length > 0 || newLocations.length > 0) && (
//                   <Grid container spacing={2} sx={{ mt: 2 }}>
//                     {newCategories.length > 0 && (
//                       <Grid item xs={12} md={6}>
//                         <Typography variant="h6">New Categories Added:</Typography>
//                         <TableContainer component={Paper} sx={{ mt: 2 }}>
//                           <Table>
//                             <TableHead>
//                               <TableRow sx={{ backgroundColor: '#9E9E9E' }}>
//                                 <TableCell sx={{ color: '#FFFFFF' }}>
//                                   <Typography variant="body1" fontWeight="bold">Category Name</Typography>
//                                 </TableCell>
//                               </TableRow>
//                             </TableHead>
//                             <TableBody>
//                               {newCategories.map((category, index) => (
//                                 <TableRow key={index} sx={{ backgroundColor: '#F2F2F2' }}>
//                                   <TableCell><Typography variant="body1">{category}</Typography></TableCell>
//                                 </TableRow>
//                               ))}
//                             </TableBody>
//                           </Table>
//                         </TableContainer>
//                       </Grid>
//                     )}
//                     {newLocations.length > 0 && (
//                       <Grid item xs={12} md={6}>
//                         <Typography variant="h6">New Locations Added:</Typography>
//                         <TableContainer component={Paper} sx={{ mt: 2 }}>
//                           <Table>
//                             <TableHead>
//                               <TableRow sx={{ backgroundColor: '#9E9E9E' }}>
//                                 <TableCell sx={{ color: '#FFFFFF' }}>
//                                   <Typography variant="body1" fontWeight="bold">Location Name</Typography>
//                                 </TableCell>
//                               </TableRow>
//                             </TableHead>
//                             <TableBody>
//                               {newLocations.map((location, index) => (
//                                 <TableRow key={index} sx={{ backgroundColor: '#F2F2F2' }}>
//                                   <TableCell><Typography variant="body1">{location}</Typography></TableCell>
//                                 </TableRow>
//                               ))}
//                             </TableBody>
//                           </Table>
//                         </TableContainer>
//                       </Grid>
//                     )}
//                   </Grid>
//                 )}
//               </>
//             ) : (
//               <Box sx={{ textAlign: 'center', mt: 4 }}>
//                 <Typography variant="h4" sx={{ color: 'green', mb: 2 }}>All entries have been successfully added.</Typography>
//                 <Typography variant="body1">Successfully imported entries: {successfulImports} / {totalEntries}</Typography>
//               </Box>
//             )}
//           </>
//         )}
//       </DialogContent>
//       {!showAddEquipment && (
//         <DialogActions>
//           <Button onClick={onClose} color="primary">Close</Button>
//         </DialogActions>
//       )}
//     </Dialog>
//   );
// };

// export default ImportEquipment;
