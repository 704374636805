import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { format, getMinutes } from "date-fns";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import { InputLabel, Select, MenuItem, FormControl } from "@mui/material";

const colors = ["#FF5733", "#33FF57", "#3357FF", "#F3FF33", "#FF33D4"];

const moreBtn = {
  borderRadius: "15px",
  fontSize: "10px",
};

const downDiv = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: 2,
  fontSize: "2px",
};

const getRandomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)];
};

const lightenColor = (color, opacity) => {
  let r = parseInt(color.slice(1, 3), 16);
  let g = parseInt(color.slice(1, 5).slice(2, 4), 16);
  let b = parseInt(color.slice(1, 7).slice(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const BookingCard = ({ bookingName, timeDuration, id, reservationList, marginStart=0, type = true }) => {
  console.log('BookingCard reservationList',reservationList);

  const randomColor = getRandomColor();
  const lightBackgroundColor = lightenColor(randomColor, 0.07); // Adjust the opacity value to get the desired lightness
  const [more, setMore] = useState(false);

  const [idData, setId] = useState(0);
  const [why, setWhy] = useState(false);
  const [whyValue, setWhyValue] = useState("");

  const [booking, setBooking] = useState(bookingName);
  const [timeDur, setTime] = useState(timeDuration);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [userName,setUserName] = useState("");
  const [marginAdd, setMargin] = useState(marginStart);
  // console.log(marginAdd)
  const whyOptions = [
    "Machine Not Working",
    "Previous Booking extension",
    "Machine Unavailable",
    "Other",
  ];

  const StyledFormControl = styled(FormControl)(({ theme }) => ({
    margin: theme.spacing(2, 0),
    minWidth: 120,
    "& .MuiInputLabel-root": {
      color: "#666",
      fontWeight: 500,
    },
    "& .MuiInputBase-root": {
      borderRadius: 8,
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      transition: "box-shadow 0.3s ease-in-out",
      "&:hover": {
        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
      },
    },
    "& .MuiSelect-select": {
      padding: theme.spacing(1.5),
    },
  }));

  useEffect(() => {
    if (reservationList[idData]) {
      const data = reservationList[idData];
      setBooking(data.name);
      const timediff = (
        (new Date(data.end_time) - new Date(data.start_time)) /
        (60000 * 60)
      ).toFixed(2);
      setTime(timediff);
      setStartTime(format(new Date(data.start_time), "HH:mm"));
      setEndTime(format(new Date(data.end_time), "HH:mm"));
      setApprovalStatus(data.approved);
      setUserName(data.reserver);
      setMargin(getMinutes(new Date(data.start_time))/10)
    }
  }, [idData, reservationList]);

  const handleleft = (idx) => {
    if (idData > 0) {
      console.log("clicked left ");
      setId(idData - 1);
    } else {
      console.log("clicked left index can't decrease");
    }
  };

  const handleRight = (idx) => {
    const valueCheck = reservationList.length - 1 > idData;
    if (valueCheck) {
      console.log("clicked right");
      setId(idData + 1);
    } else {
      console.log("clicked right index can't increase");
    }
  };

  return (
    <>
      <Card
        sx={{
          // width: "11.5rem",
          borderLeft: `3px solid ${randomColor}`,
          backgroundColor: lightBackgroundColor,
          height: "auto",
          maxWidth: "20rem",
          width: "full",
          marginTop: `${marginAdd}rem`,
          paddingBottom: type ? `${(timeDur - 1) * 6.5}rem` : "0rem",
          position: 'relative'
        }}
      >
        <CardContent sx={{ justifyContent: "space-between", paddingBottom: 0 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 1,
            }}
          >
            <Typography
              variant="h7"
              component="div"
              sx={{ flexGrow: 1, textAlign: "left" }}
            >
              {booking}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: "12px" }}
            >
              {Math.floor(timeDur)} hr {Math.round((timeDur - Math.floor(timeDur)) * 60)} mins
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: "12px" }}
            >
              {startTime} - {endTime}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: "12px", marginTop: "4px" }}
            >
              Approval Status: {approvalStatus}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: "12px", marginTop: "4px", marginBottom:'-20px' }}
            >
              User : {userName}
            </Typography>
          </Box>

          {reservationList.length > 1 ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ ...moreBtn, position: 'absolute', bottom: '8px', right: '8px', zIndex:'10000' }}
                onClick={() => setMore(true)}
              >
                More
              </Button>
            ) : null}
        </CardContent>
      </Card>
      
      {more ? (
        <>
          <Card
            sx={{
              border: "2px",
              borderRadius: "1.2rem",
              marginTop: "1rem",
              maxWidth: "20rem",
              position: "relative",
              zIndex: 20,
            }}
          >
            <CardContent
              sx={{ justifyContent: "space-between", paddingBottom: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "right",
                  justifyContent: "space-between",
                }}
              >
                <IconButton
                  onClick={() => {
                    setMore(false);
                  }}
                  size="medium"
                  sx={{ marginLeft: "auto" }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              {reservationList.map((data, idx) => {
                return idData === idx ? (
                  <>
                    <Box
                      key={idx}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 1,
                      }}
                    >
                      {" "}
                      <IconButton onClick={handleleft}>
                        <ChevronLeftIcon sx={{ color: "#212121" }} />
                      </IconButton>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ fontWeight: 600 }}
                      >
                        {data.name}
                      </Typography>
                      <IconButton onClick={handleRight}>
                        <ChevronRightIcon sx={{ color: "#212121" }} />
                      </IconButton>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "left",
                        marginTop: 2,
                        fontSize: "2px",
                      }}
                    >
                      <Typography
                        component="div"
                        sx={{ fontSize: "1rem", padding: "3px" }}
                      >
                        Booked by : {data.reserver}
                      </Typography>
                      <Typography
                        component="div"
                        sx={{ fontSize: "1rem", padding: "3px" }}
                      >
                        Time : {format(new Date(data.start_time), "HH:mm")} to{" "}
                        {format(new Date(data.end_time), "HH:mm")}
                      </Typography>
                      <Typography
                        component="div"
                        sx={{ fontSize: "1rem", padding: "3px" }}
                      >
                        Project :{" "}
                        {data.project === null ? "Null" : data.project}
                      </Typography>
                      {/* <Typography
                        component="div"
                        sx={{ fontSize: "1rem", padding: "3px" }}
                      >
                        Purpose : Purpose
                      </Typography> */}
                      <Typography
                        component="div"
                        sx={{ fontSize: "1rem", padding: "3px" }}
                      >
                        Approved : {data.approved}
                      </Typography>
                    </Box>
                    <Button
                      onClick={() => setWhy(true)}
                      sx={{
                        width: "100%",
                        border: "2px solid #1976d2",
                        borderRadius: ".8rem",
                        marginTop: "10px",
                      }}
                    >
                      Cancel
                    </Button>
                    {why === true ? (
                      <>
                        <Typography
                          sx={{
                            color: "#333",
                            textAlign: "left",
                            fontFamily: "'Roboto', sans-serif",
                            fontSize: "1.2rem",
                            fontWeight: 700,
                            // lineHeight: "1.5",
                            letterSpacing: "0.02rem",
                            marginTop: "2rem",
                          }}
                        >
                          Why?
                        </Typography>
                        <StyledFormControl fullWidth sx={{ marginTop: 1 }}>
                          <Select
                            labelId="why"
                            id="why"
                            name="why"
                            value={whyValue}
                            onChange={(event) =>
                              setWhyValue(event.target.value)
                            }
                          >
                            {whyOptions.map((option, index) => (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                          <Button
                            // onClick={() => setWhy(true)}
                            sx={{
                              width: "100%",
                              border: "2px solid #1976d2",
                              borderRadius: ".8rem",
                              marginTop: "10px",
                            }}
                          >
                            Done
                          </Button>
                        </StyledFormControl>
                      </>
                    ) : null}
                  </>
                ) : null;
              })}
            </CardContent>
          </Card>
        </>
      ) : null}
    </>
  );
};

export default BookingCard;