// OrgDashboard.js
import React, { useEffect, useState } from 'react';
import { Box, Grid, Container } from '@mui/material';
import TinkerLabListAPIs from '../../utilities/api/TinkersLab/TinkerLabListAPIs';
import UserCard from './OrgDashboardGraphs/UserCard';
import WorkshopsChart from './OrgDashboardGraphs/WorkshopsChart';
import ProjectsChart from './OrgDashboardGraphs/ProjectsChart';
import IssuesChart from './OrgDashboardGraphs/IssuesChart';
import IssuesResolvedChart from './OrgDashboardGraphs/IssuesResolvedChart';
import MachinesUsedChart from './OrgDashboardGraphs/MachinesUsedChart';
import TutorialsWatchedChart from './OrgDashboardGraphs/TutorialsWatchedChart';
import BookingHoursChart from './OrgDashboardGraphs/BookingHoursChart';
import ActivityOfUsersChart from './OrgDashboardGraphs/ActivityOfUsersChart';
import MostIssuedItemsChart from './OrgDashboardGraphs/MostIssuedItemsChart';
import CostVSIssueChart from './OrgDashboardGraphs/CostVSIssueChart';
import GeneralStatsCard from './OrgDashboardGraphs/GeneralStatsCard';
import AdditionalStatsCard from './OrgDashboardGraphs/AdditionalStatsCard';
import TLStatsTable from './OrgDashboardGraphs/TLStatsTable';

const OrgDashboard = () => {
  const [tlMappings, setTlMappings] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await TinkerLabListAPIs.TinkerLabsListGet();
        const labelMapping = {};
        data.forEach(item => {
          if (item.college) {
            labelMapping[item.id] = item.name;
          }
        });
        setTlMappings(labelMapping);
      } catch (error) {
        console.error('Error fetching TL mappings:', error);
      }
    }

    fetchData();
  }, []);

  console.log(tlMappings);

  return (
    <Container sx={{ marginTop: '1rem' }}>
        <Box >
        <UserCard tlMappings={tlMappings} />
        <Grid container spacing={2} sx={{mb:'20px'}}>
            <Grid item xs={12} md={4}>
            <WorkshopsChart tlMappings={tlMappings} />
            </Grid>
            <Grid item xs={12} md={4}>
            <ProjectsChart tlMappings={tlMappings} />
            </Grid>
            <Grid item xs={12} md={4}>
            <IssuesChart tlMappings={tlMappings} />
            </Grid>
        </Grid>


        <Grid container spacing={0} sx={{mb:'20px', textAlign:'-webkit-center'}}>
            <Grid item xs={12} md={12}>
            <CostVSIssueChart tlMappings={tlMappings} />
            </Grid>
            {/* <Grid item xs={12} md={4}>
            <GeneralStatsCard tlMappings={tlMappings} />
            </Grid> */}
        </Grid>
        {/* <Grid container spacing={2} sx={{mb:'20px'}}>
            <Grid item xs={12} md={8}>
            <CostVSIssueChart tlMappings={tlMappings} />
            </Grid>
            <Grid item xs={12} md={4}>
            <GeneralStatsCard tlMappings={tlMappings} />
            </Grid>
        </Grid> */}


        <Grid container spacing={1} sx={{mb:'20px'}}>
            <Grid item xs={12} md={6}>
            <IssuesResolvedChart tlMappings={tlMappings} />
            </Grid>
            <Grid item xs={12} md={6} sx={{ml:"-20px"}}>
            <MachinesUsedChart tlMappings={tlMappings}/>
            </Grid>
            {/* <Grid item xs={12} md={4} sx={{ml:"20px"}}>
            <TutorialsWatchedChart />
            </Grid> */}
        </Grid>
        {/* <Grid container spacing={1} sx={{mb:'20px'}}>
            <Grid item xs={12} md={4}>
            <IssuesResolvedChart tlMappings={tlMappings} />
            </Grid>
            <Grid item xs={12} md={4} sx={{ml:"-20px"}}>
            <MachinesUsedChart tlMappings={tlMappings}/>
            </Grid>
            <Grid item xs={12} md={4} sx={{ml:"20px"}}>
            <TutorialsWatchedChart />
            </Grid>
        </Grid> */}

          <Grid container spacing={2} sx={{mb:'20px'}}>
            <Grid item xs={12}>
            <AdditionalStatsCard tlMappings={tlMappings} />
            </Grid>
        </Grid>
        <Grid container spacing={2} sx={{mb:'20px'}}>
            <Grid item xs={12} md={4}>
            <BookingHoursChart tlMappings={tlMappings} />
            </Grid>
            <Grid item xs={12} md={4}>
            <ActivityOfUsersChart tlMappings={tlMappings} />
            </Grid>
            <Grid item xs={12} md={4}>
            <MostIssuedItemsChart tlMappings={tlMappings} />
            </Grid>
        </Grid>
        <Grid container spacing={2} sx={{mb:'20px'}}>
            <Grid item xs={12}>
            <TLStatsTable tlMappings={tlMappings} />
            </Grid>
        </Grid>
        </Box>
    </Container>
  );
};

export default OrgDashboard;
