import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  Box,
  Typography,
  InputBase,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  useTheme,
  useMediaQuery,
  Checkbox,
  ListItemText,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import nodatafound from '../../utilities/dummy_assets/No data-cuate 1.jpg';
import AdminReservationList from '../../utilities/api/reservation/AdminReservationList';
import { useSelector } from 'react-redux';

const SelectFilter = ({ label, value, options, onChange }) => (
  <FormControl sx={{ minWidth: 200 }}>
    <InputLabel shrink sx={{ marginLeft: '18px' }}>{label}</InputLabel>
    <Select
      multiple
      value={value}
      onChange={onChange}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
      sx={{ height: '3rem', width: '100%' }}
      input={<OutlinedInput label={label} />}
      renderValue={(selected) => selected.join(', ')}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          <Checkbox checked={value.indexOf(option) > -1} />
          <ListItemText primary={option} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const ReservationsTable = () => {
  const user = useSelector(state => state.user.user);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [machines, setMachines] = useState([]);
  const [locations, setLocations] = useState([]);
  const [approvalStatuses, setApprovalStatuses] = useState([]);
  const [project, setProject] = useState([]);
  const [machine, setMachine] = useState([]);
  const [location, setLocation] = useState([]);
  const [approvalStatus, setApprovalStatus] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);

  const approvalOptions = useMemo(() => [
    { value: 'Pending', label: 'Pending' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Rejected', label: 'Rejected' },
    // { value: 'AutoApproved', label: 'AutoApproved' },
    // { value: 'AutoRejected', label: 'AutoRejected' },
  ], []);

  const approvalMapping = useMemo(() => ({
    Pending: '0',
    Approved: '1',
    Rejected: '2',
    AutoApproved: '3',
    AutoRejected: '4',
  }), []);

  const fetchData = async () => {
    try {
      const data = await AdminReservationList.ReservationListGet();
      setRows(data.reverse());
      setProjects([...new Set(data.map(item => item.project_name))]);
      setMachines([...new Set(data.map(item => item.name))]);
      setLocations([...new Set(data.map(item => item.location))]);
      setApprovalStatuses([...new Set(data.map(item => item.approved))]);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  const handlePageChange = useCallback((newPage) => setPage(newPage), []);

  const handleStatusChange = useCallback((id) => async (event) => {
    const newStatus = event.target.value;
    const newStatusValue = approvalMapping[newStatus];
    const originalRow = rows.find(row => row.id === id);
    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, approved: newStatus, approved_status: newStatusValue } : row
    );
    setRows(updatedRows);

    try {
      await AdminReservationList.ReservationDetailPatch(id, { approved_status: newStatusValue });
    } catch (error) {
      console.error(`Error updating detail for ID ${id}:`, error.response ? error.response.data : error.message);
      setRows(rows.map((row) =>
        row.id === id ? originalRow : row
      ));
    }
  }, [rows, approvalMapping]);

  const filteredRows = useMemo(() => {
    const lowerSearchQuery = searchQuery.toLowerCase();
    return rows.filter((row) => {
      return (
        (!project.length || project.includes(row.project_name)) &&
        (!machine.length || machine.includes(row.name)) &&
        (!location.length || location.includes(row.location)) &&
        (!approvalStatus.length || approvalStatus.includes(row.approved)) &&
        (!searchQuery ||
          (row.reserver?.toLowerCase().includes(lowerSearchQuery) ||
          row.project_name?.toLowerCase().includes(lowerSearchQuery) ||
          row.reserved_date?.toLowerCase().includes(lowerSearchQuery) ||
          row.location?.toLowerCase().includes(lowerSearchQuery) ||
          row.name?.toLowerCase().includes(lowerSearchQuery) ||
          `${new Date(row.start_time).toLocaleString()} to ${new Date(row.end_time).toLocaleString()}`.toLowerCase().includes(lowerSearchQuery)))
      );
    });
  }, [rows, project, machine, location, approvalStatus, searchQuery]);

  const default_image = '';

  const columns = useMemo(() => [
    {
      field: 'item_details',
      headerName: 'Item Details',
      flex: 1,
      headerAlign: 'center',
      headerClassName: 'header-style',
      renderCell: (params) => (
        <Box sx={{ ml:'15%', display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <img src={params.row?.image || nodatafound} alt={params.row?.name || 'No Text'} style={{ width: 50, height: 50, borderRadius: '50%' }} />
          </Box>
          <Box>
            <Typography variant="subtitle2" align="center">{params.row?.name || 'No Text'}</Typography>
            <Typography variant="body2" sx={{ color: 'blue' }} align="center">{params.row?.location || 'No Subtext'}</Typography>
          </Box>
        </Box>
      ),
    },
    { 
      field: 'reserver', 
      headerName: 'Student Name', 
      flex: 1, 
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'header-style',
    },
    { 
      field: 'project_name', 
      headerName: 'Project Name', 
      flex: 1, 
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'header-style',
    },
    {
      field: 'start_time',
      headerName: 'From Time',
      flex: 1,
      headerClassName: 'header-style',
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => (
        <Typography style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {new Date(row.start_time).toLocaleString()}
        </Typography>
      ),
    },
    {
      field: 'end_time',
      headerName: 'To Time',
      flex: 1,
      headerClassName: 'header-style',
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => (
        <Typography style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {new Date(row.end_time).toLocaleString()}
        </Typography>
      ),
    },
    {
      field: 'action',
      headerName: 'Approval Status',
      sortable: false,
      width: 200,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'header-style',
      renderCell: ({ row }) => (
        <Select
          value={row.approved || ''}
          onChange={handleStatusChange(row.id)}
          displayEmpty
          sx={{ width: '100%', height: '75%' }}
          disabled={(row.approved === "Rejected") || (row.approved === "AutoRejected")}
        >
          {approvalOptions.map((option) => (
            <MenuItem key={option.value} value={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      ),
    },
  ], [handleStatusChange, approvalOptions]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ height: '60%', width: '97.5%', marginLeft: '1rem' }}>
      <Box
        component="form"
        onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 1,
          width: '100%',
          marginBlock: '1rem',
          boxSizing: 'border-box',
        }}
      >
        <SelectFilter label="Select Project" value={project} options={projects} onChange={(event) => setProject(event.target.value)} />
        <SelectFilter label="Select Machine" value={machine} options={machines} onChange={(event) => setMachine(event.target.value)} />
        <SelectFilter label="Select Location" value={location} options={locations} onChange={(event) => setLocation(event.target.value)} />
        <SelectFilter label="Select Approval Status" value={approvalStatus} options={approvalStatuses} onChange={(event) => setApprovalStatus(event.target.value)} />
        <InputBase
          sx={{
            maxWidth: 200,
            ml: 2,
            mr: 1,
            flex: 1,
            height: '3rem',
            border: '#B5B5B5 solid 0.5px',
            borderRadius: '10px',
            bgcolor: '#F4F4F4',
            '& .MuiInputBase-input': {
              paddingLeft: '.1rem',
            },
          }}
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <IconButton sx={{ paddingLeft: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', marginTop: '100px', padding: '32px 24px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '21px' }}>
          <Box component="img" src={nodatafound} sx={{ width: 211.889, height: 196.824, flexShrink: 0 }} />
          <Typography sx={{ color: '#273F77', textAlign: 'center', fontFamily: 'Inter', fontSize: 26, fontWeight: 500, lineHeight: '150%', letterSpacing: '-0.352px' }}>
            Loading...
          </Typography>
        </Box>
      ) : filteredRows.length === 0 ? (
        <Box sx={{ display: 'flex', marginTop: '100px', padding: '32px 24px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '21px' }}>
          <Box component="img" src={nodatafound} sx={{ width: 211.889, height: 196.824, flexShrink: 0 }} />
          <Typography sx={{ color: '#273F77', textAlign: 'center', fontFamily: 'Inter', fontSize: 26, fontWeight: 500, lineHeight: '150%', letterSpacing: '-0.352px' }}>
            No Records Found!
          </Typography>
        </Box>
      ) : (
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSize={7}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPageOptions={[7]}
          pagination
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          disableDensitySelector
          disableExport
          disableMultipleColumnsSorting
          sortingOrder={[]}
          disableColumnSort
          sx={{
            '& .header-style': {
              fontSize: '1.2rem',
            },
          }}
        />
      )}
    </Box>
  );
};

export default ReservationsTable;
