import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { MenuComponent } from '../components/Menu';
import NavBar from '../components/Navbar/Navbar';
import LookerStudioEmbed from '../components/dashboard/LookerStudioEmbed';
import DashboardContent from '../components/dashboard/DashboardContent';
import OrgDashboard from '../components/dashboard/OrgDashboard';

const Dashboard = ({ collapsed, setCollapsed }) => {
  const {user}  = useSelector((state) => state.user.user); // Retrieve user from Redux store

  console.log('Dashboard User',user);

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Box sx={{ width: collapsed ? '7%' : '17.5%' }}>
        <MenuComponent collapsed={collapsed} setCollapsed={setCollapsed} />
      </Box>

      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <NavBar />
        {user.TL_name === 'Org-Admin' ? (
          <OrgDashboard />
        ) : (
          <DashboardContent />
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
